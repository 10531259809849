import * as THREE from "three";
import vert from "./custom.vert";
import frag from "./custom.frag";

export default class CustomMaterial extends THREE.ShaderMaterial {
  constructor(cfg) {
    cfg = {
      ...{
        diffuseTexture: null,
        textureTiling: 1.0,
        flowTexture: null,
        flowSpeed: 0.5,
        flowStrength: 1.0,
        flowOffset: new THREE.Vector2(0, 0),
        flowUvJump: new THREE.Vector2(0, 0),
        flowNormalTexture: null,
        normalScale: 1.0,
        time: 0.0,
        lightPosition: new THREE.Vector3(0, 1.0, 0),
        transparent: false,
      },
      ...cfg,
    };

    super({
      uniforms: {
        u_diffuseTexture: { value: cfg.diffuseTexture },
        u_textureTiling: { value: cfg.textureTiling },
        u_time: { value: cfg.time },
      },
      vertexShader: vert,
      fragmentShader: frag,
      transparent: cfg.transparent,
    });

    if (cfg.flowTexture) {
      this.defines.HAS_FLOW_TEXTURE = true;
      this.uniforms.u_flowTexture = { value: cfg.flowTexture };
      this.uniforms.u_flowSpeed = { value: cfg.flowSpeed };
      this.uniforms.u_flowStrength = { value: cfg.flowStrength };
      this.uniforms.u_flowUvJump = { value: cfg.flowUvJump };
      this.uniforms.u_flowOffset = { value: cfg.flowOffset }; //practical values are 0 and −0.5
      this.uniforms.u_flowNormalTexture = { value: cfg.flowNormalTexture };
      this.uniforms.u_normalScale = { value: cfg.normalScale };
      this.uniforms.u_lightPosition = { value: cfg.lightPosition };

    }
  }

  update(dt) {
    this.uniforms.u_time.value += dt;
  }
}
