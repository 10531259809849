import { reactive/*, readonly*/ } from "vue";

const state = reactive({
  webGLInstance: null,
  socketInstance: null,
  assets: null,
  webcamDIV: null,
  reflectorRTT: null,
  reflectorTextureMatrix: null,
  activeSceneIndex: 0,
  isSceneChanging: null,
  sceneTransitionRatio: null,
  sceneTransitionDuration: 0.5,
  sharedUniforms: {
    u_shadowMapDepthTexture: null
  },
  keyWIsDown: null,
  keyAIsDown: null,
  keySIsDown: null,
  keyDIsDown: null,
  keyOkIsDown: null
});


export default {
  //state: readonly(state),
  state: state,
  actions: {
    addWebGLInstance(_instance) {
      state.webGLInstance = _instance;
    },
    addSocketInstance(_instance) {
      state.socketInstance = _instance;
    },
    setAssets(_assets) {
      state.assets = _assets;
    },
    setWebcamDIV(stream) {
      state.webcamDIV = stream;
    },
    setReflectorRTT(_rtt) {
      state.reflectorRTT = _rtt;
    },
    setReflectorTextureMatrix(_rtt) {
      state.reflectorTextureMatrix = _rtt;
    },
    setActiveSceneIndex(_value) {
      state.activeSceneIndex = _value;
    },
    setIsSceneChanging(_bool) {
      state.isSceneChanging = _bool;
    },
    setSceneTransitionRatio(_value) {
      state.sceneTransitionRatio = _value;
    },
    setSceneTransitionDuration(_value) {
      state.sceneTransitionDuration = _value;
    },
    setSharedUniforms(_uniformKey, _value) {
      state.sharedUniforms[_uniformKey] = _value
    },
    setKeyWIsDown(_bool) {
      state.keyWIsDown = _bool;
    },
    setKeyAIsDown(_bool) {
      state.keyAIsDown = _bool;
    },
    setKeySIsDown(_bool) {
      state.keySIsDown = _bool;
    },
    setKeyDIsDown(_bool) {
      state.keyDIsDown = _bool;
    },
    setKeyOkIsDown(_bool) {
      state.keyOkIsDown = _bool;
    }
  },
  getters: {
    webGLInstance() {
      return state.webGLInstance;
    },
    socketInstance() {
      return state.socketInstance;
    },

    assets() {
      return state.assets;
    },
    webcamDIV() {
      return state.webcamDIV;
    },
    reflectorRTT() {
      return state.reflectorRTT;
    },
    reflectorTextureMatrix() {
      return state.reflectorTextureMatrix;
    },
    activeSceneIndex() {
      return state.activeSceneIndex;
    },
    isSceneChanging() {
      return state.isSceneChanging;
    },
    sceneTransitionRatio() {
      return state.sceneTransitionRatio;
    },
    sceneTransitionDuration() {
      return state.sceneTransitionDuration;
    },
    sharedUniforms() {
      return state.sharedUniforms;
    },
    keyWIsDown() {
      return state.keyWIsDown;
    },
    keyAIsDown() {
      return state.keyAIsDown;
    },
    keySIsDown() {
      return state.keySIsDown;
    },
    keyDIsDown() {
      return state.keyDIsDown;
    },
    keyOkIsDown() {
      return state.keyOkIsDown;
    }
  }
};
