/* eslint-disable no-unused-vars */
import store from "@/store.js";
import * as THREE from "three";
import * as Helpers from '@/utils/helpers.js';

class CellMeshes {
  constructor() {
    this.container = new THREE.Object3D();

    this.meshCube = null;
    this.meshSphere = null;
    this.meshOctahedron = null;
    this.meshTorus = null;

    this.centerVector3 = new THREE.Vector3(0, 0, 0);
    this.directionVector3 = new THREE.Vector3(0, 0, 0);
    this.keyWwasDown = false;

    this.activeRatioSpeed = 1;
    this.activeRatio = 0;
    this.isDown = false;

    this.keyWIsDown = false;
    this.keyWActiveRatio = 0;

    this.keyAIsDown = false;
    this.keyAActiveRatio = 0;

    this.keySIsDown = false;
    this.keySActiveRatio = 0;

    this.keyDIsDown = false;
    this.keyDActiveRatio = 0;
  }

  init() {
    const assets = store.getters.assets();

    const cubeTexture = assets.cubecellao;
    cubeTexture.flipY = false;
    cubeTexture.encoding = THREE.sRGBEncoding;

    const sphereTexture = assets.spherecellao;
    sphereTexture.flipY = false;
    sphereTexture.encoding = THREE.sRGBEncoding;

    const octahedronTexture = assets.octahedronao;
    octahedronTexture.flipY = false;
    octahedronTexture.encoding = THREE.sRGBEncoding;

    const torusTexture = assets.torusao;
    torusTexture.flipY = false;
    torusTexture.encoding = THREE.sRGBEncoding;

    this.meshCube = assets.cubecell.scene.clone();
    this.meshSphere = assets.spherecell.scene.clone();
    this.meshOctahedron = assets.octahedroncell.scene.clone();
    this.meshTorus = assets.toruscell.scene.clone();


    this.setMaterial(this.meshCube, cubeTexture);
    this.setMaterial(this.meshSphere, sphereTexture);
    this.setMaterial(this.meshOctahedron, octahedronTexture);
    this.setMaterial(this.meshTorus, torusTexture);

    this.scatterMesh(this.meshCube, 10);
    this.scatterMesh(this.meshSphere, 12);
    this.scatterMesh(this.meshOctahedron, 14);
    this.scatterMesh(this.meshTorus, 10);

    this.container.add(this.meshCube);
    this.container.add(this.meshSphere);
    this.container.add(this.meshOctahedron);
    this.container.add(this.meshTorus);
  }


  setMaterial(mesh, texture) {
    const material = new THREE.MeshBasicMaterial({ map: texture, color: '#38d77f' })
    mesh.traverse((child) => {
      if (child.isMesh) {
        child.material = material;
        child.castShadow = true;
      }
    });
  }


  scatterMesh(mesh, radius) {
    mesh.scale.set(0.25, 0.25, 0.25)
    for (let index = 0; index < mesh.children.length; index++) {
      const element = mesh.children[index];

      element.userData.defaultPosition = element.position.clone();
      element.userData.defaultQuaternion = element.quaternion.clone();
      element.userData.velocity = element.position.distanceTo(this.centerVector3) / radius;

      const subvec = this.directionVector3.subVectors(element.position, this.centerVector3);

      element.position.set(subvec.x * radius, subvec.y * radius, subvec.z * radius);
      element.userData.scatterPosition = element.position.clone();

      // const quaternion = new THREE.Quaternion(); // create one and reuse it

      // quaternion.setFromUnitVectors(new THREE.Vector3(0,00,0), element.userData.defaultPosition);
      // element.applyQuaternion(quaternion);

      // const angle_in_radians = element.position.angleTo(this.centerVector3);

      // console.log(angle_in_radians)
      // element.rotation.x = angle_in_radians;// angle_in_radians;
      // element.rotation.y = angle_in_radians;// angle_in_radians;
      // element.rotation.z = angle_in_radians;// angle_in_radians;

      //console.log(this.directionVector3.subVectors(element.position, new THREE.Vector3(0, 0, 0)))


      //SPERICAL SCATTEIRNG
      // this.domeSize = 10;
      // const distance = this.domeSize / 2;
      // const theta = THREE.Math.randFloatSpread(Math.PI * 2);
      // const phi = THREE.Math.randFloatSpread(Math.PI * 2);

      // element.position.x = distance * Math.sin(theta) * Math.cos(phi);
      // element.position.y = distance * Math.cos(theta);
      // element.position.z = distance * Math.sin(theta) * Math.sin(phi);
    }


  }

  animateScatterMesh(mesh, activeRatio) {
    for (let i = 0; i < mesh.children.length; i++) {
      const element = mesh.children[i];
      element.position.lerp(element.userData.scatterPosition, 0.04 / element.userData.velocity * activeRatio);
    }
  }


  animateAssembleMesh(mesh, activeRatio) {
    for (let index = 0; index < mesh.children.length; index++) {
      const element = mesh.children[index];
      element.position.lerp(element.userData.defaultPosition, 0.02 / element.userData.velocity * activeRatio);
    }
  }



  update(_dt) {
    if (store.getters.keyWIsDown() && !this.keyAIsDown) {
      this.keyWIsDown = true;
    }

    if (store.getters.keyAIsDown() && !this.keyWIsDown) {
      this.keyAIsDown = true;
    }

    if (store.getters.keySIsDown() && !this.keyWIsDown) {
      this.keySIsDown = true;
    }

    if (store.getters.keyDIsDown() && !this.keyDIsDown) {
      this.keyDIsDown = true;
    }


    if (this.keyWIsDown) {
      this.keyWActiveRatio += _dt * this.activeRatioSpeed;
      if (this.keyWActiveRatio > 1) {
        this.keyWIsDown = false;
        this.keyWActiveRatio = 0;
      }

      this.animateAssembleMesh(this.meshSphere, this.keyWActiveRatio);
      this.animateScatterMesh(this.meshCube, this.keyWActiveRatio);
      this.animateScatterMesh(this.meshOctahedron, this.keyWActiveRatio);
      this.animateScatterMesh(this.meshTorus, this.keyWActiveRatio);
    }

    if (this.keyAIsDown) {
      this.keyAActiveRatio += _dt * this.activeRatioSpeed;
      if (this.keyAActiveRatio > 1) {
        this.keyAIsDown = false;
        this.keyAActiveRatio = 0;
      }

      this.animateAssembleMesh(this.meshCube, this.keyAActiveRatio);
      this.animateScatterMesh(this.meshSphere, this.keyAActiveRatio);
      this.animateScatterMesh(this.meshOctahedron, this.keyAActiveRatio);
      this.animateScatterMesh(this.meshTorus, this.keyAActiveRatio);
    }

    if (this.keySIsDown) {
      this.keySActiveRatio += _dt * this.activeRatioSpeed;
      if (this.keySActiveRatio > 1) {
        this.keySIsDown = false;
        this.keySActiveRatio = 0;
      }

      this.animateAssembleMesh(this.meshOctahedron, this.keySActiveRatio);
      this.animateScatterMesh(this.meshSphere, this.keySActiveRatio);
      this.animateScatterMesh(this.meshCube, this.keySActiveRatio);
      this.animateScatterMesh(this.meshTorus, this.keySActiveRatio);
    }

    if (this.keyDIsDown) {
      this.keyDActiveRatio += _dt * this.activeRatioSpeed;
      if (this.keyDActiveRatio > 1) {
        this.keyDIsDown = false;
        this.keyDActiveRatio = 0;
      }

      this.animateAssembleMesh(this.meshTorus, this.keyDActiveRatio);
      this.animateScatterMesh(this.meshSphere, this.keyDActiveRatio);
      this.animateScatterMesh(this.meshCube, this.keyDActiveRatio);
      this.animateScatterMesh(this.meshOctahedron, this.keyDActiveRatio);
    }

    this.meshSphere.rotation.y += 0.01 + this.keyWActiveRatio * 0.02;
    this.meshCube.rotation.y -= 0.01 + this.keyAActiveRatio * 0.0175;
    this.meshOctahedron.rotation.y += 0.01 + this.keySActiveRatio * 0.02;
    this.meshTorus.rotation.y = this.meshTorus.rotation.z -= 0.01 + this.keyDActiveRatio * 0.0175;
  }
}

export default new CellMeshes();