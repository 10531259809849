/* eslint-disable no-unused-vars */
import store from "@/store.js";
import * as THREE from "three";
import groundVert from './groundVert.vert';
import groundFrag from './groundFrag.frag';
import * as dat from 'dat.gui';

class Ground {
  constructor() {
    this.container = new THREE.Object3D();
    this.mesh = null;
    this.colors = {
      top: '#ff009e',
      bottom: '#f5d17d',
      fadeout: '#000000'
    };
  }


  init() {
    const gridTexture = store.getters.assets().grid;
    gridTexture.wrapS = gridTexture.wrapT = THREE.RepeatWrapping;

    const radialGradient = store.getters.assets().radialgradient;

    const size = 6;

    const geometry = new THREE.PlaneGeometry(size, size, 50, 50);

    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_colorA: { value: new THREE.Color(this.colors.top) },
        u_colorB: { value: new THREE.Color(this.colors.bottom) },
        u_colorFadeout: { value: new THREE.Color(this.colors.fadeout) },
        u_time: { value: 0.0 },
        u_speed: { value: 0.75 },
        u_gridTexture: { value: gridTexture },
        u_maskTexture: { value: radialGradient },
        u_textureRepeat: { value: 10 },
      },
      vertexShader: groundVert,
      fragmentShader: groundFrag,
      transparent: true,
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.rotation.x = -Math.PI / 2;
    this.mesh.rotation.z = -Math.PI / 2;
    this.container.add(this.mesh);

    if (window.location.hash === "#debug") {
      const folder = new dat.GUI();

      folder
        .addColor(this.colors, 'top')
        .onChange((value) => {
          this.mesh.material.uniforms.u_colorA.value = new THREE.Color(value);
        })
        .name('ground top');

      folder
        .addColor(this.colors, 'bottom')
        .onChange((value) => {
          this.mesh.material.uniforms.u_colorB.value = new THREE.Color(value);
        })
        .name('ground bottom');

      folder
        .addColor(this.colors, 'fadeout')
        .onChange((value) => {
          this.mesh.material.uniforms.u_colorFadeout.value = new THREE.Color(value);
        })
        .name('ground fadeout');

      folder.open();
    }
  }

  update(dt) {
    this.mesh.material.uniforms.u_time.value += dt;
  }
}

export default new Ground();