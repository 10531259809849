/* eslint-disable no-unused-vars */
import * as THREE from "three";
import store from "@/store.js";
import skyDomeVert from './skyDome.vert';
import skyDomeFrag from './skyDome.frag';

import sunVert from './sunVert.vert';
import sunFrag from './sunFrag.frag';



import * as dat from 'dat.gui';

class Sky {
  constructor() {
    this.container = new THREE.Object3D();
    this.meshSun = null;

    this.dome = null;
    this.domeSize = 200;

    this.colors = {
      top: '#75004c',
      bottom: '#05000c',
    };
  }

  init() {
    this.createDome();
    this.createSun();

    if (window.location.hash === "#debug") {
      const skyFolder = new dat.GUI();

      skyFolder
        .addColor(this.colors, 'top')
        .onChange((value) => {
          this.dome.material.uniforms.u_topColor.value = new THREE.Color(value);
        })
        .name(' col top');

      skyFolder
        .addColor(this.colors, 'bottom')
        .onChange((value) => {
          this.dome.material.uniforms.u_bottomColor.value = new THREE.Color(value);
        })
        .name(' col bot');

      skyFolder
        .add(this.dome.material.uniforms.u_exponent, 'value', -5, 5, 0.0001)
        .onChange((_value) => {
          this.dome.material.uniforms.u_exponent.value = _value;
        })
        .name('exponent');

      skyFolder
        .add(this.dome.material.uniforms.u_offset, 'value', -0.3, 1, 0.0001)
        .onChange((_value) => {
          this.dome.material.uniforms.u_offset.value = _value;
        })
        .name('offset');

      skyFolder.open();
    }
  }

  createSun() {
    const geometry = new THREE.PlaneGeometry(1, 1, 100, 100);

    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_texture: { value: store.getters.assets().synthsun },
        u_time: { value: 0 },
      },
      vertexShader: sunVert,
      fragmentShader: sunFrag,
      transparent: true
    });


    this.meshSun = new THREE.Mesh(geometry, material);
    this.meshSun.scale.set(100, 100);
    this.meshSun.position.set(0, 20, -50);
    window.sun = this.meshSun;
    this.container.add(this.meshSun);
  }

  createDome() {
    const geometry = new THREE.SphereBufferGeometry(this.domeSize, 16, 16);
    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_topColor: { value: new THREE.Color(this.colors.top) },
        u_bottomColor: { value: new THREE.Color(this.colors.bottom) },
        u_nightEffectColorTop: { value: new THREE.Color(this.colors.top) },
        u_nightEffectColorBottom: { value: new THREE.Color(this.colors.bottom) },
        u_offset: { value: -0.3 },
        u_exponent: { value: 1.9 },
        u_nigthModeActiveRatio: { value: 0 },
      },
      vertexShader: skyDomeVert,
      fragmentShader: skyDomeFrag,
      side: THREE.BackSide,
      fog: false,
    });

    this.dome = new THREE.Mesh(geometry, material);
    // this.dome.frustumCulled = false;
    // this.dome.matrixAutoUpdate = false;
    // this.dome.updateMatrix();
    this.container.add(this.dome);
    window.dome = this.dome;

  }


  update(_dt) {
    this.meshSun.material.uniforms.u_time.value += _dt;
  }
}

export default new Sky();