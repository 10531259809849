/* eslint-disable no-unused-vars */
import * as THREE from "three";
import store from "@/store.js";
// import runnerVert from './runnerVert.vert';
// import runnerFrag from './runnerFrag.frag';


import { SkeletonUtils } from "three-stdlib";

class Runner {
  constructor() {
    this.container = new THREE.Object3D();
    this.containerBullets = new THREE.Object3D();
    this.model = null;
    this.mixer = null;
  }

  init() {
    this.initRunner();
  }


  initRunner() {
    const assets = store.getters.assets();

    const material = new THREE.MeshPhongMaterial({ color: 0x040404, shininess: 100/*, specular: 0xF26B9F*/ });

    this.model = SkeletonUtils.clone(assets.runner.scene);
    this.model.traverse((child) => {
      if (child.isMesh) {
        //child.geometry.attributes.uv2 = child.geometry.attributes.uv;
        child.material = material;
        child.castShadow = true;
        // child.material = new THREE.ShaderMaterial({
        //   uniforms: {
        //     u_color: { value: new THREE.Color(0xffffff * Math.random()) },
        //     u_time: { value: 0.0 },
        //   },
        //   vertexShader: runnerVert,
        //   fragmentShader: runnerFrag,
        // });
      }
    });

    this.mixer = new THREE.AnimationMixer(this.model);
    this.mixer.clipAction(assets.runner.animations[0]).play();

    this.container.add(this.model);
  }


  update(_dt, _elapsedTime) {
    this.mixer.update(_dt);
  }
}

export default new Runner();