/**
 * Full-screen textured quad shader
 */

const ColorCorrectionShader = {

  uniforms: {

    'tDiffuse': { value: null },
    'opacity': { value: 1.0 },
    'brightness': { value: 0.0 },
    'contrast': { value: 0.0 }
  },

  vertexShader: /* glsl */`
		varying vec2 vUv;
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}`,

  fragmentShader: /* glsl */`
		uniform float opacity;
		uniform float brightness;
		uniform float contrast;
		uniform sampler2D tDiffuse;
		varying vec2 vUv;


    vec3 adjustBrightness(vec3 color, float value) {
      return color + value;
    }
    
    vec3 adjustContrast(vec3 color, float value) {
      return 0.5 + (1.0 + value) * (color - 0.5);
    }

		void main() {
      vec4 color = texture2D( tDiffuse, vUv );


      color.rgb = adjustBrightness(color.rgb, brightness); // yields a lighter colour

      color.rgb = adjustContrast(color.rgb, contrast); // yields a lighter colour

      gl_FragColor = vec4(color.rgb, color.a * opacity);

		}`

};

export { ColorCorrectionShader };