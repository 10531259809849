import store from "@/store.js";
import * as THREE from "three";
import { SkeletonUtils } from "three-stdlib";

class Remote {
  constructor() {
    this.container = new THREE.Object3D();
    this.mesh = null;
    this.time = 0;
  }

  init() {
    const assets = store.getters.assets();

    this.mesh = SkeletonUtils.clone(assets.remote.scene);

    const arrowsMaterial = new THREE.MeshPhongMaterial({ color: '#0022aa', shininess: 0 })
    const numbersMaterial = new THREE.MeshPhongMaterial({ color: '#111111', shininess: 130, specular: '#111111' })
    const defaultMaterial = new THREE.MeshPhongMaterial({ color: '#030303', shininess: 0 })
    const bodyMaterial = new THREE.MeshPhongMaterial({ color: '#020202', shininess: 30, specular: '#e0b826' })

    this.mesh.traverse((child) => {
      if (child.isMesh) {

        if (child.material.name.includes('body')) {
          child.material = bodyMaterial;
        }
        else if (child.material.name.includes('numbers')) {
          child.material = numbersMaterial;
        }
        else if (child.material.name.includes('default')) {
          child.material = defaultMaterial;
        }
        else if (child.material.name.includes('arrows')) {
          child.material = arrowsMaterial;
        }
      }
    });


    this.mesh.rotation.set(1.2, 0, 0);
    this.mesh.position.set(0, 0, 3);
    this.mesh.scale.set(0.125, 0.125, 0.125);
    window.remote = this.mesh;
    this.container.add(this.mesh);
  }


  update(_dt) {
    this.time += _dt;

    this.mesh.position.x = Math.sin(this.time * 0.7) * 0.330;
    this.mesh.position.y = Math.cos(this.time * 0.5) * 0.340;
    this.mesh.rotation.z = Math.sin(this.time * 0.5) * 0.640;
    this.mesh.rotation.y = Math.cos(this.time * 0.5) * 0.250;
  }
}

export default new Remote();

