/* eslint-disable no-unused-vars */
import store from "@/store.js";
import * as THREE from "three";
import Postprocess from "./postprocess.js";
import { OrbitControls } from "../../../../utils/OrbitControls.js";
import webcam from "./webcam/webcam.js";

export default class Scene5 {
  // eslint-disable-next-line no-unused-vars
  constructor(_appEngine, _webglContainer, _systemInfo) {
    this.isDebugActive = window.location.hash === "#debug" ? true : false;
    this.hasWebcam = true;
    this.hasRendered = false;
    this.appEngine = _appEngine;
    this.assets = store.getters.assets();
    this.camera = this.initCamera(_webglContainer);
    this.scenes = this.initScenes(_webglContainer);
    this.post = this.initPost(_appEngine, _webglContainer, this.scenes, this.camera, _systemInfo, this.datGUI);
    this.onResize(_webglContainer.clientWidth, _webglContainer.clientHeight, this.appEngine.camera);
  }



  initCamera(_webglContainer) {
    const camera = new THREE.PerspectiveCamera(
      70,
      _webglContainer.clientWidth / _webglContainer.clientHeight,
      0.1,
      50
    );
    camera.position.set(0, 0, 0.1);
    window.testscene3camera = camera;
    return camera;
  }


  initScenes(_webglContainer) {
    const sceneA = new THREE.Scene();//Bloom scene
    sceneA.background = new THREE.Color('#D31616').convertSRGBToLinear();

    const sceneB = new THREE.Scene();

    webcam.init(this.post);
    sceneA.add(webcam.container);

    this.orbitControls = new OrbitControls(this.camera, _webglContainer);

    return { sceneA, sceneB };
  }


  initPost(_appEngine, _webglContainer, _scenes, _camera, _datGUI) {
    const { sceneA, sceneB } = _scenes;

    const post = new Postprocess(
      _appEngine,
      _webglContainer,
      sceneA,
      sceneB,
      _camera,
      _datGUI
    );

    return post;
  }


  async startWebcam() {
    webcam.toggleLoaderTitle(true);
    await webcam.startWebcam();
    webcam.toggleLoaderTitle(false);
  }

  async stopWebcam() {
    webcam.toggleLoaderTitle(false);
    await webcam.stopWebcam();
  }

  onResize(_webglContainerWidth, _webglContainerHeight) {
    this.camera.aspect = _webglContainerWidth / _webglContainerHeight;
    this.camera.updateProjectionMatrix();
    webcam.onResize(_webglContainerWidth, _webglContainerHeight, this.camera);
    this.post.onResize(_webglContainerWidth, _webglContainerHeight);
  }

  animate(_dt) {
    webcam.update(_dt);
    webcam.post = this.post;
    this.post.render();
  }
}