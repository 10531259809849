/**
 * Runs minor funcs to detect and return current device features.
 */


export function getSystemInfo() {
  const data = {
    nodeEnv: getNodeEnv(),
    packageVersion: getPackageVersion(),
    os: getOS(),
    browser: getBrowser(),
    screenResolution: getScreenResolution(),
    dpr: getDpr(),
    isWebGL1: getIsWebGL1(),
    isWebGL2: getIsWebGL2(),
    isIE11: getIE11(),
    isTouchDevice: getIsTouchDevice(),
    isMobileUA: getIsMobileUA(),
    isMobile: getIsMobile(),
    isTablet: getIsTablet(),
    isDesktop: getIsDesktop(),
    deviceOrientation: getDeviceOrientation(),
    isFullscreenAPI: getIsFullscreenAPI(),
    isWebWorkerAPI: getIsWebWorkerAPI(),
    isServiceWorkerAPI: getIsServiceWorkerAPI(),
    isWasmAPI: getIsWasmAPI(),
    isPushManager: getIsPushManager(),
    isPwaDisplayMode: getIsPwaDisplayMode(),
    isA2HSAccepted: getIsA2HSAccepted(),
    isIntersectionAPI: getIntersectionObserverAPI(),
    isVideoTypeWebm: getIsVideoTypeWebm(),
    isVisibilityAPI: getIsVisibilityAPI(),
    isBlendMode: checkForStyle("mix-blend-mode"),
    isScrollSnap: checkForStyle("scroll-snap-align"),
    isSerialAPI: getIsSerialAPI()
  }
  //if (data.nodeEnv === "development") {
  logSystemInfo(data);
  //}
  return data;
  //Idea? - this.os = _this.detect(["exokit"]) && "linux" == navigator.platform ? "magicleap" : _this.detect(["ipad", "iphone", "ios"]) || _this.detect("mac") && _this.touchCapable && Math.max(screen.width, screen.height) < 1370 ? "ios" : _this.detect(["android", "kindle"]) ? "android" : _this.detect(["blackberry"]) ? "blackberry" : _this.detect(["mac os"]) ? "mac" : _this.detect(["windows", "iemobile"]) ? "windows" : _this.detect(["linux"]) ? "linux" : "unknown",
  /**
   * //Needs more testing - GPUidentifying
   * this.isBlacklistedGPU = this.getIsBlacklistedGPU(this.glContext);
   */
}


function getNodeEnv() {
  return process.env.NODE_ENV;
}


function getPackageVersion() {
  return process.env.PACKAGE_VERSION;
}


function getOS() {
  const sUserAgent = navigator.userAgent;
  const isWin = (navigator.platform === 'Win32') || (navigator.platform === 'Windows');
  const isMac = (navigator.platform === 'Mac68K') || (navigator.platform === 'MacPPC') || (navigator.platform === 'Macintosh') || (navigator.platform === 'MacIntel');

  if (isMac) return 'Mac';
  const isUnix = (navigator.platform === 'X11') && !isWin && !isMac;
  if (isUnix) return 'Unix';
  const isIOS = ['iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
  if (isIOS) return 'iOS';
  const isLinux = (String(navigator.platform).indexOf('Linux') > -1);
  if (isLinux) return 'Linux';
  if (isWin) {
    const isWin2K = sUserAgent.indexOf('Windows NT 5.0') > -1 || sUserAgent.indexOf('Windows 2000') > -1;
    if (isWin2K) return 'Win2000';
    const isWinXP = sUserAgent.indexOf('Windows NT 5.1') > -1 || sUserAgent.indexOf('Windows XP') > -1;
    if (isWinXP) return 'WinXP';
    const isWin2003 = sUserAgent.indexOf('Windows NT 5.2') > -1 || sUserAgent.indexOf('Windows 2003') > -1;
    if (isWin2003) return 'Win2003';
    const isWinVista = sUserAgent.indexOf('Windows NT 6.0') > -1 || sUserAgent.indexOf('Windows Vista') > -1;
    if (isWinVista) return 'WinVista';
    const isWin7 = sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 7') > -1;
    if (isWin7) return 'Win7';
    const isWin10 = sUserAgent.indexOf('Windows NT 10') > -1 || sUserAgent.indexOf('Windows 10') > -1;
    if (isWin10) return 'Win10';
  }


  return 'other';
}

function getIE() {
  const ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return 'IE' + parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return 'IE' + parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return 'Edge' + parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}


function getBrowser() {
  const agent = navigator.userAgent.toLowerCase();
  const regStr_ff = /firefox\/[\d.]+/gi;
  const regStr_chrome = /chrome\/[\d.]+/gi;
  const regStrChrome2 = /ipad; cpu os (\d+_\d+)/gi;
  const regStr_saf = /safari\/[\d.]+/gi;
  //const regStr_edg = /edg\/[\d.]+/gi;

  // firefox
  if (agent.indexOf('firefox') > 0) {
    return agent.match(regStr_ff);
  }

  // Safari
  if (agent.indexOf('safari') > 0 && agent.indexOf('chrome') < 0) {
    let tmpInfo;
    if (agent.indexOf('ipad') > 0) {
      tmpInfo = agent.match(regStrChrome2).toString().replace('ipad; cpu os ', 'ipados/');
    } else {
      tmpInfo = agent.match(regStr_saf);
      tmpInfo = tmpInfo.toString().replace('version', 'safari');
    }
    return tmpInfo;
  }

  // IE / Eege
  const tmpIsIE = getIE();
  if (tmpIsIE) {
    return tmpIsIE;
  }

  // Chrome
  if (agent.indexOf('chrome') > 0) {
    return agent.match(regStr_chrome);
  }

  return 'other';
}


function getScreenResolution() {
  return {
    width: window.screen.width,
    height: window.screen.height
  }
}


function getDpr() {
  return window.devicePixelRatio < 2 ? 1.25 : 1.8;
}




// function getGLContext(_webglNamesArray) {
//   const canvas = document.createElement("canvas");
//   let gl;
//   _webglNamesArray.forEach(name => {
//     gl = canvas.getContext(name, { stencil: true });
//   });
//   return gl;
// }


function getIsWebGL1() {
  const canvas = document.createElement("canvas");
  const gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  return gl && gl instanceof WebGLRenderingContext;
}



function getIsWebGL2() {
  const canvas = document.createElement("canvas");
  const gl = canvas.getContext("webgl2") || canvas.getContext("experimental-webgl2");
  return gl && gl instanceof WebGL2RenderingContext;
}

function getIE11() {
  return !!window.MSInputMethodContext && !!document.documentMode;
}


function getIsTouchDevice() {
  return (("ontouchstart" in window || "onpointerdown" in window));
}


function getIsMobileUA() {
  const mobileUAs = ['android', 'ios', 'iphone', 'ipad'];
  return mobileUAs.some(_ele => navigator.userAgent.toLowerCase().includes(_ele));
}


function getIsMobile() {
  return (getIsTouchDevice() && getIsMobileUA() && getScreenResolution().width < 1000);
}


function getIsTablet() {
  return (getIsTouchDevice() && getIsMobileUA() && getScreenResolution().width > 1000);
}


function getIsDesktop() {
  return !(getIsMobile() || getIsTablet());
}


function getDeviceOrientation() {
  return ((window.matchMedia("(orientation: portrait)").matches) ? 'portrait' : 'landscape');
}


function getIsFullscreenAPI() {
  return document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled;
}


function getIsWebWorkerAPI() {
  return !!window.Worker;
}


function getIsServiceWorkerAPI() {
  return 'serviceWorker' in navigator;
}


function getIsWasmAPI() {
  return (() => {
    try {
      if (
        typeof WebAssembly === "object" &&
        typeof WebAssembly.instantiate === "function"
      ) {
        const module = new WebAssembly.Module(
          Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
        );
        if (module instanceof WebAssembly.Module) {
          return (
            new WebAssembly.Instance(module) instanceof WebAssembly.Instance
          );
        }
      }
    } catch (_error) { console.log(_error) }
    return false;
  })();
}


function getIsPushManager() {
  return 'PushManager' in window;
}


function getIsPwaDisplayMode() {
  return ((window.matchMedia("(display-mode: fullscreen)").matches ||
    window.matchMedia("(display-mode: standalone)").matches) ||
    //For Safari
    ('standalone' in navigator || 'fullscreen' in navigator)
  );
}


function getIsA2HSAccepted() {
  //Checks if local storage contains previously saved key during installation in installview.vue
  return JSON.parse(window.localStorage.getItem("A2HS_status"));
}


function getIntersectionObserverAPI() {
  let state;
  if (!('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
    state = false
  } else {
    state = true
  }
  return state;
}


function getIsVideoTypeWebm() {
  const video = document.createElement('video')
  const isCanPlaySupported = !!video.canPlayType;
  const isCanPlayTypeWebm = (video.canPlayType('video/webm; codecs="vp8, vorbis"') === "probably") ? true : false;
  return isCanPlayTypeWebm && isCanPlaySupported;
}


function getIsVisibilityAPI() {
  let hidden;
  let visibilityChange;
  if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
    return {
      "visibilityChange": visibilityChange,
      "hidden": hidden,
    }
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
    return {
      "visibilityChange": visibilityChange,
      "hidden": hidden,
    }
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
    return {
      "visibilityChange": visibilityChange,
      "hidden": hidden,
    }
  } else {
    return false;
  }
}


function checkForStyle(prop) {
  let _tagDiv;
  const vendors = ["Khtml", "ms", "O", "Moz", "Webkit"];
  if (prop in (_tagDiv = _tagDiv || document.createElement("div")).style) return !0;
  prop = prop.replace(/^[a-z]/, (val) => val.toUpperCase());
  for (let i = vendors.length - 1; i >= 0; i--) if (vendors[i] + prop in _tagDiv.style) return !0;
  return !1;
}

function getIsSerialAPI() {
  return 'serial' in navigator;
}


function logSystemInfo(_data) {
  for (let [key, value] of Object.entries(_data)) {
    if (typeof value === 'object' && value !== null) {
      for (let [key_b, value_b] of Object.entries(value)) {
        console.log(`[Detector] ${key} ${key_b}: ${value_b}`);
      }
    } else {
      console.log(`[Detector] ${key}: ${value}`);
    }
  }
}


//Needs more testing - GPU identifying
// getIsBlacklistedGPU(_glContext) {
//   const debugInfo = _glContext.getExtension("WEBGL_debug_renderer_info");
//   const currentGpuName = _glContext.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL).name.toLowerCase();
//   const blacklistedGpuNames = ["radeon hd 6970m", "radeon hd 6770m", "radeon hd 6490m", "radeon hd 6630m", "radeon hd 6750m", "radeon hd 5750", "radeon hd 5670", "radeon hd 4850", "radeon hd 4870", "radeon hd 4670", "geforce 9400m", "geforce 320m", "geforce 330m", "geforce gt 130", "geforce gt 120", "geforce gtx 285", "geforce 8600", "geforce 9600m", "geforce 9400m", "geforce 8800 gs", "geforce 8800 gt", "quadro fx 5", "quadro fx 4", "radeon hd 2600", "radeon hd 2400", "radeon hd 2600", "radeon r9 200", "mali-4", "mali-3", "mali-2", "google swiftshader", "sgx543", "legacy", "sgx 543"];
//   return (blacklistedGpuNames.some(_ele => currentGpuName.includes(_ele))) ? true : false;
// },
