import * as THREE from "three";
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry.js';


class Room {
  constructor() {
    this.container = new THREE.Object3D();
    this.mesh = null;
    this.texture = null;
  }

  init() {
    this.mesh = new THREE.LineSegments(
      new BoxLineGeometry(20, 20, 20, 10, 10, 10),
      new THREE.LineBasicMaterial({ color: '#00ff00' })
    );
    this.mesh.geometry.translate(0, 0, 0);

    this.container.add(this.mesh);
  }

  update() {
    return;
  }
}

export default new Room();

