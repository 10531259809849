<template>
	<section class="loadingComp">
		<div class="loadingComp_loader">
			<div class="bar"></div>
			<div class="bar"></div>
			<div class="bar"></div>
			<div class="bar"></div>
			<div class="bar"></div>
			<div class="bar"></div>
		</div>
	</section>
</template>

<script>
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
//import { BasisTextureLoader } from "three/examples/jsm/loaders/BasisTextureLoader.js";
import { normalizeString } from '@/utils/helpers.js';
import { assets } from './assets.js';

export default {
	name: 'LoadingComp',
	data() {
		return {
			staticAssetsPath: `${process.env.BASE_URL}staticAssets/`,
			startTime: 0,
		};
	},
	mounted() {
		const assetsToLoad = assets;
		this.loadAssets(assetsToLoad).then((_loadedAssets) => {
			this.onAssetsLoaded(_loadedAssets);
		});
	},
	methods: {
		async loadAssets(_assetsToLoad) {
			this.startTime = performance.now();
			THREE.Cache.enabled = true;
			const manager = new THREE.LoadingManager();
			let dracoLoader = new DRACOLoader();
			let loader;
			const assetsPool = [];

			try {
				return new Promise((_resolve, _reject) => {
					_assetsToLoad.forEach((_asset) => {
						const fileExtension = normalizeString(_asset.fileName.split('.').pop());

						const assetName = normalizeString(_asset.fileName.split('.').shift());

						switch (fileExtension) {
							case 'hdr':
								loader = new RGBELoader(manager);
								loader.setDataType(THREE.UnsignedByteType);
								loader.load(
									`${_asset.path}`,
									(_texture) => {
										assetsPool[assetName] = _texture;
									},
									undefined,
									// eslint-disable-next-line no-unused-vars
									(_error) => {
										console.log('[LoadingComp] error hdr:', assetName);
									},
								);
								break;
							case 'png':
							case 'jpg':
							case 'jpeg':
								loader = new THREE.TextureLoader(manager);
								loader.setCrossOrigin('');
								loader.load(
									`${_asset.path}`,
									(_texture) => {
										_texture.format =
											fileExtension === 'png'
												? THREE.RGBAFormat
												: THREE.RGBFormat;
										_texture.encoding = THREE.LinearEncoding;
										assetsPool[assetName] = _texture;
									},
									undefined,
									// eslint-disable-next-line no-unused-vars
									(_error) => {
										console.log('[LoadingComp] error png/jpg/jpeg:', assetName);
									},
								);
								break;
							case 'gltf':
							case 'glb':
							case 'drc':
								loader = new GLTFLoader(manager);
								dracoLoader.setDecoderPath(
									`${process.env.BASE_URL}staticAssets/data/js/draco/`,
								);
								loader.setDRACOLoader(dracoLoader);
								loader.load(
									`${_asset.path}`,
									(_gltf) => {
										assetsPool[assetName] = _gltf;
									},
									undefined,
									(_error) => {
										console.log('[LoadingComp] error gltf:', assetName, _error);
									},
								);
								break;
							case 'json':
								loader = new THREE.FileLoader(manager);
								loader.load(
									`${_asset.path}`,
									(_json) => {
										assetsPool[assetName] = JSON.parse(_json);
									},
									// eslint-disable-next-line no-unused-vars
									(_xhr) => {
										return;
									},
									(_err) => {
										console.log('[LoadingComp] error - json:', _err);
									},
								);
								break;
							default:
								console.log(
									`[LoadingComp] Error loading asset: ${_asset.name}. Unrecognized file extension: ${fileExtension}`,
								);
								break;
						}
					});

					manager.onLoad = async () => {
						_resolve(assetsPool);
						if (process.env.NODE_ENV === 'development')
							console.log('[LoadingComp] assets loaded: ', assetsPool);
					};

					manager.onError = (_url) => {
						console.log('[LoadingComp] manager errror:', _url);
						_reject(_url);
					};
				});
			} catch (_err) {
				console.log('[LoadingComp] error:', _err.message);
			}
		},

		onAssetsLoaded(_loadedAssets) {
			if (performance.now() - this.startTime > 1000) {
				this.$emit('on-loading-finished', _loadedAssets);
			} else {
				setTimeout(() => {
					this.$emit('on-loading-finished', _loadedAssets);
				}, 500);
			}
		},
	},
};
</script>

<style lang="scss">
@import '@/styles/colors.scss';

.loadingComp {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background: $color-black;
	z-index: 2;

	&_loader {
		margin: 0 auto;
		width: 194px;
		height: 120px;
		text-align: center;
		font-size: 10px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);

		> div {
			height: 100%;
			width: 30px;
			display: inline-block;
			float: left;
			margin-left: 2px;
			animation: delay 0.8s infinite ease-in-out;
		}

		.bar {
			&:nth-child(1) {
				background-color: #ff0000;
			}
			&:nth-child(2) {
				background-color: #00ff00;
				animation-delay: -0.7s;
			}
			&:nth-child(3) {
				background-color: #0000ff;
				animation-delay: -0.6s;
			}
			&:nth-child(4) {
				background-color: #ee00ff;
				animation-delay: -0.5s;
			}
			&:nth-child(5) {
				background-color: #9000ff;
				animation-delay: -0.4s;
			}
			&:nth-child(6) {
				background-color: #ffff00;
				animation-delay: -0.3s;
			}
		}
	}

	@keyframes delay {
		0%,
		40%,
		100% {
			transform: scaleY(0.05);
			opacity: 0.5;
		}
		20% {
			transform: scaleY(1);
			opacity: 1;
		}
	}
}
</style>
