/* eslint-disable no-unused-vars */
import store from "@/store.js";
import * as THREE from "three";
import Postprocess from "./postprocess.js";
//import { OrbitControls } from "../../../../utils/OrbitControls.js";
import cellMeshes from "./cellMeshes/cellMeshes.js";

export default class Scene7 {
  // eslint-disable-next-line no-unused-vars
  constructor(_appEngine, _webglContainer, _systemInfo) {
    this.isDebugActive = window.location.hash === "#debug" ? true : false;
    this.hasRendered = false;
    this.appEngine = _appEngine;
    this.assets = store.getters.assets();
    this.camera = this.initCamera(_webglContainer);
    this.scenes = this.initScenes(_webglContainer);
    this.post = this.initPost(_appEngine, _webglContainer, this.scenes, this.camera, _systemInfo, this.datGUI);
    this.onResize(_webglContainer.clientWidth, _webglContainer.clientHeight, this.appEngine.camera);
    return this;
  }


  initCamera(_webglContainer) {
    const camera = new THREE.PerspectiveCamera(
      70,
      _webglContainer.clientWidth / _webglContainer.clientHeight,
      0.1,
      50
    );

    camera.position.set(0, 0, 6.0);
    camera.lookAt(new THREE.Vector3(0, -0.5, 0));
    return camera;
  }



  initScenes(_webglContainer) {
    const sceneA = new THREE.Scene();//Bloom scene
    sceneA.background = new THREE.Color('#020202')

    const sceneB = new THREE.Scene();

    cellMeshes.init();
    sceneA.add(cellMeshes.container);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0.0, 3, 0); //default; light shining from top
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.width = 1024;
    directionalLight.shadow.mapSize.height = 1024;
    directionalLight.shadow.camera.near = 0.01;
    directionalLight.shadow.camera.far = 6.2;
    directionalLight.shadow.camera.left = -3;
    directionalLight.shadow.camera.bottom = -3;
    directionalLight.shadow.camera.right = 3;
    directionalLight.shadow.camera.top = 3;
    window.directionalLight = directionalLight;
    sceneA.add(directionalLight);

    //Create a helper for the shadow camera (optional)
    // const helper = new THREE.CameraHelper(lightFront.shadow.camera);
    // sceneA.add(helper);

    const geometry = new THREE.PlaneGeometry(10, 10, 1, 1);
    geometry.rotateX(- Math.PI / 2);

    const material = new THREE.ShadowMaterial();
    material.opacity = 1.0;

    const plane = new THREE.Mesh(geometry, material);
    plane.position.y = -3;
    plane.receiveShadow = true;
    sceneA.add(plane);

    //this.orbitControls = new OrbitControls(this.camera, _webglContainer);

    return { sceneA, sceneB };
  }


  initPost(_appEngine, _webglContainer, _scenes, _camera, _datGUI) {
    const { sceneA, sceneB } = _scenes;

    const post = new Postprocess(
      _appEngine,
      _webglContainer,
      sceneA,
      sceneB,
      _camera,
      _datGUI
    );

    return post;
  }


  onResize(_webglContainerWidth, _webglContainerHeight) {
    this.camera.aspect = _webglContainerWidth / _webglContainerHeight;
    this.camera.updateProjectionMatrix();
    this.post.onResize(_webglContainerWidth, _webglContainerHeight);
  }


  animate(_dt) {
    cellMeshes.update(_dt);
    this.post.render();
  }
}