const texturePath = `${process.env.BASE_URL}staticAssets/textures/`;
const modelPath = `${process.env.BASE_URL}staticAssets/models/`;

export const assets = [
  //FS-Quad
  {
    path: `${texturePath}testc.jpg`,
    fileName: "testc.jpg",
  },
  {
    path: `${texturePath}displ.jpg`,
    fileName: "displ.jpg",
  },
  //Arwing
  {
    path: `${modelPath}arwing/arwing.glb`,
    fileName: "arwing.glb",
  },
  {
    path: `${modelPath}skyline.glb`,
    fileName: "skyline.glb",
  },
  {
    path: `${texturePath}grid.jpg`,
    fileName: "grid.jpg",
  },
  {
    path: `${texturePath}synthSun.png`,
    fileName: "synthSun.png",
  },
  //Runner
  {
    path: `${modelPath}runner/runner.glb`,
    fileName: "runner.glb",
  },
  {
    path: `${texturePath}radialGradient.jpg`,
    fileName: "radialGradient.jpg",
  },
  //Flowmap
  {
    path: `${texturePath}flowmapX.png`,
    fileName: "flowmapX.png",
  },
  {
    path: `${texturePath}granite-normal.jpg`,
    fileName: "granitenormal.jpg",
  },
  {
    path: `${texturePath}granite-diffuse.jpg`,
    fileName: "granitediffuse.jpg",
  },
  //Cell
  {
    path: `${modelPath}cubeCell.glb`,
    fileName: "cubeCell.glb",
  },
  {
    path: `${texturePath}cubeCell_ao.jpg`,
    fileName: "cubeCell_ao.jpg",
  },
  {
    path: `${modelPath}spherecell.glb`,
    fileName: "spherecell.glb",
  },
  {
    path: `${texturePath}sphereCell_ao.jpg`,
    fileName: "sphereCell_ao.jpg",
  },
  {
    path: `${modelPath}octahedronCell.glb`,
    fileName: "octahedronCell.glb",
  },
  {
    path: `${texturePath}octahedron_ao.jpg`,
    fileName: "octahedron_ao.jpg",
  },
  {
    path: `${modelPath}torusCell.glb`,
    fileName: "torusCell.glb",
  },
  {
    path: `${texturePath}torus_ao.jpg`,
    fileName: "torus_ao.jpg",
  },
  //scene 9 home scene/welcome
  {
    path: `${modelPath}remote.glb`,
    fileName: "remote.glb",
  },
  {
    path: `${texturePath}pressStart.jpg`,
    fileName: "pressStart.jpg",
  },
];
