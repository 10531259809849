import store from "@/store.js";
import * as THREE from "three";
import Postprocess from "./postprocess.js";
//import { GUI } from "three/examples/jsm/libs/dat.gui.module.js";
import runner from "./runner/runner.js";
import ground from "./ground/ground.js";
import * as Helpers from '@/utils/helpers.js';
import { OrbitControls } from "../../../../utils/OrbitControls.js";

export default class Scene3 {
  constructor(_appEngine, _webglContainer, _systemInfo) {
    this.isDebugActive = window.location.hash === "#debug" ? true : false;
    //this.datGUI = this.isDebugActive ? new GUI({ width: 300 }) : null;
    this.hasRendered = false;
    this.appEngine = _appEngine;
    this.assets = store.getters.assets();
    this.orbitControls = null;
    this.camera = this.initCamera(_webglContainer);
    this.scenes = this.initScenes(_webglContainer);
    this.post = this.initPost(_appEngine, _webglContainer, this.scenes, this.camera, _systemInfo, this.datGUI);
    this.onResize(_webglContainer.clientWidth, _webglContainer.clientHeight, this.appEngine.camera);
    this.activeRatioSpeed = 1.0;
    this.keyWActiveRatio = 0;
    this.keyAActiveRatio = 0;
    this.keySActiveRatio = 0;
    this.keyDActiveRatio = 0;

    return this;
  }


  initCamera(_webglContainer) {
    const camera = new THREE.PerspectiveCamera(
      70,
      _webglContainer.clientWidth / _webglContainer.clientHeight,
      0.1,
      20
    );
    camera.position.set(3.0, 2.5, 3.0);
    window.scene3Camera = camera;
    return camera;
  }


  initScenes(_webglContainer) {
    const sceneA = new THREE.Scene();//Bloom scene
    sceneA.background = new THREE.Color('#F26B9F');

    const sceneB = new THREE.Scene();

    runner.init();
    sceneA.add(runner.container);

    ground.init();
    sceneA.add(ground.container);

    const lightFront = new THREE.DirectionalLight(0xffffff, 1);
    lightFront.position.set(0.0, 2, 2); //default; light shining from top
    lightFront.castShadow = true;
    lightFront.shadow.mapSize.width = 1024;
    lightFront.shadow.mapSize.height = 1024;
    lightFront.shadow.camera.near = 0.01;
    lightFront.shadow.camera.far = 5;
    window.lightFront = lightFront;
    sceneA.add(lightFront);

    const lightBack = new THREE.DirectionalLight(0xffffff, 1);
    lightBack.position.set(0.0, 2, -2); //default; light shining from top
    sceneA.add(lightBack);

    // const helper = new THREE.DirectionalLightHelper(light, 2);
    // sceneA.add(helper);

    //SHADOW PLANE
    const geometry = new THREE.PlaneGeometry(4, 4);
    geometry.rotateX(- Math.PI / 2);
    const material = new THREE.ShadowMaterial();
    material.opacity = 0.75;

    const plane = new THREE.Mesh(geometry, material);
    plane.receiveShadow = true;
    plane.position.y = 0.01;
    window.shadow = plane;
    sceneA.add(plane);

    //const texture = new THREE.VideoTexture(store.getters.webcamDIV());
    this.orbitControls = new OrbitControls(this.camera, _webglContainer);
    this.orbitControls.maxPolarAngle = 1.3;
    return { sceneA, sceneB };
  }


  initPost(_appEngine, _webglContainer, _scenes, _camera, _datGUI) {
    const { sceneA, sceneB } = _scenes;

    const post = new Postprocess(
      _appEngine,
      _webglContainer,
      sceneA,
      sceneB,
      _camera,
      _datGUI
    );

    return post;
  }


  onResize(_webglContainerWidth, _webglContainerHeight) {
    this.camera.aspect = _webglContainerWidth / _webglContainerHeight;
    this.camera.updateProjectionMatrix();
    this.post.onResize(_webglContainerWidth, _webglContainerHeight);
  }

  animate(_dt) {
    this.keyWActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keyWActiveRatio + (store.getters.keyWIsDown() ? _dt : -_dt) / this.activeRatioSpeed),
      0.0,
      1.0
    );

    this.keyAActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keyAActiveRatio + (store.getters.keyAIsDown() ? _dt : -_dt) / this.activeRatioSpeed),
      0.0,
      1.0
    );


    this.keySActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keySActiveRatio + (store.getters.keySIsDown() ? _dt : -_dt) / this.activeRatioSpeed),
      0.0,
      1.0
    );

    this.keyDActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keyDActiveRatio + (store.getters.keyDIsDown() ? _dt : -_dt) / this.activeRatioSpeed),
      0.0,
      1.0
    );
    //console.log(this.keyAActiveRatio, this.keyDActiveRatio)

    //cameraControls.rotateCameraToLeftByDegrees(this.activeRatio);//degrees
    this.orbitControls.rotateToHorizontalByDegrees((this.keyAActiveRatio * Math.PI) / 180);
    this.orbitControls.rotateToHorizontalByDegrees(-(this.keyDActiveRatio * Math.PI) / 180);
    this.orbitControls.rotateVerticalByDegrees((this.keyWActiveRatio * Math.PI) / 180);
    this.orbitControls.rotateVerticalByDegrees(-(this.keySActiveRatio * Math.PI) / 180);
    //this.controls.update();

    runner.update(_dt);
    ground.update(_dt);

    this.post.render();
  }
}