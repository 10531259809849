/* eslint-disable no-unused-vars */
import store from "@/store.js";
import * as THREE from "three";
import particlesFrag from './particlesFrag.frag';
import particlesVert from './particlesVert.vert';

class Particles {
  constructor() {
    this.appEngine = null;
    this.scene = null;
    this.camera = null;
    this.container = new THREE.Object3D();
    this.SIM_TEXTURE_WIDTH = 64;
    this.SIM_TEXTURE_HEIGHT = 64;
    this.defaultPositionTexture = null;
    this.prevPositionRenderTarget = null;
    this.currPositionRenderTarget = null;

    this.positionMaterial = null;
    this.vec3 = new THREE.Vector3();
    this.mesh = null;
  }

  init(appEngine, scene, camera) {
    this.appEngine = appEngine;
    this.scene = scene;
    this.camera = camera;
    const PARTICLE_COUNT = this.SIM_TEXTURE_WIDTH * this.SIM_TEXTURE_HEIGHT;

    let positionLifes = new Float32Array(PARTICLE_COUNT * 4);

    for (let i = 0, i4 = 0; i < PARTICLE_COUNT; i++) {
      this.vec3.set(
        Math.random() * 2 - 1,
        Math.random() * 2 - 1,
        Math.random() * 2 - 1,
      ).normalize().multiplyScalar(Math.sqrt(Math.random()));
      positionLifes[i4 + 0] = this.vec3.x;
      positionLifes[i4 + 1] = this.vec3.y;
      positionLifes[i4 + 2] = this.vec3.z;
      positionLifes[i4 + 3] = i / PARTICLE_COUNT;

      i4 += 4;
    }

    let refGeometry = new THREE.BoxBufferGeometry(1, 1, 1);

    let geometry = new THREE.InstancedBufferGeometry();

    for (let attributeId in refGeometry.attributes) {
      geometry.setAttribute(attributeId, refGeometry.attributes[attributeId]);
    }
    if (refGeometry.index) geometry.setIndex(refGeometry.getIndex());


    let simUvs = new Float32Array(PARTICLE_COUNT * 2);
    let instanceScales = new Float32Array(PARTICLE_COUNT);
    let startPositions = new Float32Array(PARTICLE_COUNT * 2);
    let speeds = new Float32Array(PARTICLE_COUNT);
    let colors_a = [];
    let colors_b = [];
    let colors_c = [];
    let colors_d = [];


    for (let i = 0, i2 = 0; i < PARTICLE_COUNT; i++) {
      let s = ((i % this.SIM_TEXTURE_WIDTH) + 0.5) / this.SIM_TEXTURE_WIDTH;
      let t = (Math.floor(i / this.SIM_TEXTURE_WIDTH) + 0.5) / this.SIM_TEXTURE_HEIGHT;
      simUvs[i2 + 0] = s;
      simUvs[i2 + 1] = t;

      instanceScales[i] = 0.01 + Math.random() * 0.03;
      speeds[i] = 0.01 + Math.random() / 10;
      //green/purple 
      colors_a.push(0.25, Math.random(), 0.50, Math.random());
      //pink/yellow 
      colors_b.push(1.0, Math.random(), 0.50, Math.random());
      //tripple style
      colors_c.push(Math.random(), Math.random(), 1.0, Math.random());
      //red/orange 
      colors_d.push(1.0, Math.random(), 0.15, Math.random());

      i2 += 2;
    }

    geometry.setAttribute('positionLifes', new THREE.InstancedBufferAttribute(positionLifes, 4));
    geometry.setAttribute('speeds', new THREE.InstancedBufferAttribute(speeds, 1));
    geometry.setAttribute('color_a', new THREE.InstancedBufferAttribute(new Float32Array(colors_a), 4));
    geometry.setAttribute('color_b', new THREE.InstancedBufferAttribute(new Float32Array(colors_b), 4));
    geometry.setAttribute('color_c', new THREE.InstancedBufferAttribute(new Float32Array(colors_c), 4));
    geometry.setAttribute('color_d', new THREE.InstancedBufferAttribute(new Float32Array(colors_d), 4));


    this.mesh = new THREE.Mesh(geometry, new THREE.ShaderMaterial({
      uniforms: {
        u_prevPositionTexture: { value: null },
        u_currPositionTexture: { value: null },
        u_time: { value: 0 },
        u_timeScale: { value: 1 },
        u_activeIndex: { value: 0 }
      },
      vertexShader: particlesVert,
      fragmentShader: particlesFrag,
      depthWrite: false,
      depthTest: false,
      transparent: true
    }));
    this.container.add(this.mesh);
  }


  update(_dt) {
    if (store.getters.keyWIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 0;
    }

    if (store.getters.keyAIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 1;
    }

    if (store.getters.keySIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 2;
    }

    if (store.getters.keyDIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 3;
    }

    this.mesh.material.uniforms.u_time.value += _dt * 0.5;
  }
}

export default new Particles();