/* eslint-disable no-unused-vars */
import * as THREE from "three";
import store from "@/store.js";

import skylineVert from './skylineVert.vert';
import skylineFrag from './skylineFrag.frag';

import * as dat from 'dat.gui';

class Skyline {
  constructor() {
    this.container = new THREE.Object3D();
    this.meshOuterOutline = null;
    this.meshOuter = null;
    this.meshInner = null;
    this.meshInnerOutline = null;

    this.colors = {
      outer: '#150020',
      inner: '#06000f',
      outLine: '#ffd961'
    }
  }

  init() {
    const assets = store.getters.assets();
    const model = assets.skyline.scene.clone();
    const outlineMaterial = new THREE.ShaderMaterial({
      uniforms: {
        u_color: { value: new THREE.Color(this.colors.outLine) }
      },
      vertexShader: skylineVert,
      fragmentShader: skylineFrag
    });



    //OUTER
    this.meshOuterOutline = model.clone();

    this.meshOuterOutline.traverse((child) => {
      if (child.isMesh) {
        child.material = outlineMaterial;
      }
    });
    this.meshOuterOutline.scale.set(0.0205, 0.0205, 0.0205);
    this.meshOuterOutline.position.set(0, 2.2, 44);
    this.meshOuterOutline.rotation.y = Math.random();
    window.meshOuterOutline = this.meshOuterOutline;
    this.container.add(this.meshOuterOutline);

    this.meshOuter = model.clone();
    this.meshOuter.traverse((child) => {
      if (child.isMesh) {
        child.material = new THREE.ShaderMaterial({
          uniforms: {
            u_color: { value: new THREE.Color(this.colors.outer) }
          },
          vertexShader: skylineVert,
          fragmentShader: skylineFrag
        });
      }
    });
    this.meshOuter.scale.set(0.02, 0.02, 0.02);
    this.meshOuter.position.set(0, 2, 44);
    this.meshOuter.rotation.y = this.meshOuterOutline.rotation.y;
    window.skylineOuter = this.meshOuter;
    this.container.add(this.meshOuter);


    //INNER
    this.meshInnerOutline = model.clone();
    this.meshInnerOutline.traverse((child) => {
      if (child.isMesh) {
        child.material = child.material = outlineMaterial;

      }
    });
    this.meshInnerOutline.scale.set(0.0205, 0.0205, 0.0205);
    this.meshInnerOutline.position.set(0, -1.8, 50);
    this.meshInnerOutline.rotation.y = Math.random();
    window.meshInnerOutline = this.meshInnerOutline;
    this.container.add(this.meshInnerOutline);



    this.meshInner = model.clone();
    this.meshInner.traverse((child) => {
      if (child.isMesh) {
        child.material = new THREE.ShaderMaterial({
          uniforms: {
            u_color: { value: new THREE.Color(this.colors.inner) }
          },
          vertexShader: skylineVert,
          fragmentShader: skylineFrag
        });
      }
    });
    this.meshInner.scale.set(0.02, 0.02, 0.02);
    this.meshInner.position.set(0, -2, 50);
    this.meshInner.rotation.y = this.meshInnerOutline.rotation.y;
    window.skylineInner = this.meshInner;
    this.container.add(this.meshInner);


    if (window.location.hash === "#debug") {
      const folder = new dat.GUI();

      folder
        .addColor(this.colors, 'outer')
        .onChange((value) => {
          this.meshOuter.children[0].material.uniforms.u_color.value = new THREE.Color(value);
        })
        .name('col outer');


      folder
        .addColor(this.colors, 'inner')
        .onChange((value) => {
          this.meshInner.children[0].material.uniforms.u_color.value = new THREE.Color(value);
        })
        .name('col inner');

      folder
        .addColor(this.colors, 'outLine')
        .onChange((value) => {
          this.meshOuter.children[0].material.uniforms.u_color.value = new THREE.Color(value);
        })
        .name('col outLine');





      folder.open();
    }
  }

  updateMovement(_dt) {
    if (this.meshOuter && this.meshInner) {
      this.meshOuter.rotation.y += _dt * 0.15;
      this.meshOuterOutline.rotation.y += _dt * 0.15;


      this.meshInner.rotation.y += _dt * 0.2;
      this.meshInnerOutline.rotation.y += _dt * 0.2;




    }
  }

  update(_dt) {
    this.updateMovement(_dt);
  }
}

export default new Skyline();