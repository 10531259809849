<template>
	<div id="glContainer" class="glContainer"></div>
</template>

<script>
import { inject, onMounted } from 'vue';
import WebGLApp from './index.js';

export default {
	props: {
		loadedAssets: {
			type: Array,
			required: true,
		},
		systemInfo: {
			type: Object,
			required: true,
		},
	},

	setup(props, { emit }) {
		const store = inject('store');
		let glContainer = null;
		let webGLApp = null;

		function onResize() {
			webGLApp.onResize(glContainer.clientWidth, glContainer.clientHeight);
		}

		function animate() {
			requestAnimationFrame(animate);
			webGLApp.animate();
		}

		onMounted(() => {
			glContainer = document.getElementById('glContainer');
			webGLApp = new WebGLApp(glContainer, props.systemInfo);

			store.actions.addWebGLInstance(webGLApp);
			window.addEventListener('resize', onResize);

			onResize();
			animate();
			onResize();

			emit('on-webgl-is-ready');
		});

		return {
			webGLApp,
		};
	},
};
</script>

<style lang="scss" scoped>
// @import "@/assets/scss/_mediaQueries.scss";

.glContainer {
	touch-action: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	// touch-action: none;
	// position: fixed;
	// top: 50%;
	// transform: translateY(-50%);
	// left: 0;
	// width: 100%;
	// height: calc(100% - 120px);
}
</style>
