/* eslint-disable no-unused-vars */
import store from "@/store.js";
import * as Helpers from '@/utils/helpers.js';
import * as THREE from "three";
import webcamVert from './webcamVert.vert';
import webcamFrag from './webcamFrag.frag';

class Webcam {
  constructor() {
    this.container = new THREE.Object3D();
    this.mesh = null;
    this.stream = null;
    this.videoEle = null;
    this.videoIsReady = false;

    this.activeRatioSpeed = 1.0;
    this.activeRatio = 0;

    this.post = null;

    this.loaderTitleEle = null;
  }


  init() {
    this.createLoaderTitleEle();
    this.createVideoDomEle();
    this.createVideoPlane();
  }


  createLoaderTitleEle() {
    this.loaderTitleEle = document.createElement('p');
    this.loaderTitleEle.style.position = 'absolute';
    this.loaderTitleEle.style.left = '0';
    this.loaderTitleEle.style.right = '0';
    this.loaderTitleEle.style.top = '50%';
    this.loaderTitleEle.style.transform = 'translateY( calc( 50% - 90px) )';
    this.loaderTitleEle.style.margin = '0 auto';
    this.loaderTitleEle.style.fontSize = '60px';
    this.loaderTitleEle.style.width = '356px';
    this.loaderTitleEle.style.height = '66px';
    this.loaderTitleEle.style.border = '2px solid #000000';
    this.loaderTitleEle.style.fontFamily = 'robotobold';
    this.loaderTitleEle.style.padding = '10px';
    this.loaderTitleEle.style.color = '#000000';
    this.loaderTitleEle.style.zIndex = 1;
    this.loaderTitleEle.style.transition = "opacity 0.5s";
    this.loaderTitleEle.style.opacity = '0';

    this.loaderTitleEle.innerText = "INITIALIZING";

    document.body.appendChild(this.loaderTitleEle);
  }

  createVideoDomEle() {
    const videoContainer = document.createElement('div');
    videoContainer.style.position = 'absolute';
    videoContainer.style.top = '0';
    videoContainer.style.left = '0';
    videoContainer.style.width = '100%';
    videoContainer.style.height = '100%';
    videoContainer.style.pointerEvents = 'none';

    this.videoEle = document.createElement('video');
    this.videoEle.autoplay = true;
    this.videoEle.style.width = '320px';
    this.videoEle.style.height = '240px';
    this.videoEle.style.opacity = '0.0';
    this.videoEle.style.transform = "rotateY(180deg)";
    this.videoEle.style.position = "absolute";
    this.videoEle.style.pointerEvents = 'none';

    videoContainer.appendChild(this.videoEle);

    document.body.appendChild(videoContainer);
  }


  createVideoPlane() {
    const videoTexture = new THREE.VideoTexture(this.videoEle);
    videoTexture.wrapS = videoTexture.wrapT = THREE.RepeatWrapping;

    const material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      uniforms: {
        u_time: { value: 0.0 },
        u_videoTexture: { value: videoTexture },
        u_resolution: { value: new THREE.Vector4() },
        u_activeIndex: { value: 0 },
        u_activeRatio: { value: 0.0 }
      },
      vertexShader: webcamVert,
      fragmentShader: webcamFrag,
      transparent: true
    });

    const geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1);
    this.mesh = new THREE.Mesh(geometry, material);
    this.container.add(this.mesh);
  }


  toggleLoaderTitle(bool) {
    if (bool === true) {
      this.loaderTitleEle.style.opacity = "1";
    } else {
      this.loaderTitleEle.style.opacity = "0";
    }
  }



  async startWebcam() {
    return new Promise((resolve) => {
      //get the right one for windos TV
      // const hdConstraints = {
      //   video: { width: { min: 640 }, height: { min: 320 } },
      // };
      return navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          this.stream = stream;
          this.videoEle.srcObject = this.stream;
          this.activeRatio = 0.0;
          this.videoIsReady = true;
          resolve(stream);
        })
        .catch((error) => {
          console.log('[Webcam] Something went wrong!', error);
        });
    });
  }

  stopWebcam() {
    if (this.stream)
      this.stream.getTracks().forEach((track) => {
        setTimeout(() => {
          track.stop();
          this.videoIsReady = false;
          this.mesh.material.uniforms.u_activeRatio.value = 0.0;
          this.mesh.material.uniforms.u_activeIndex.value = 0.0;
        }, 1000);
      });
  }


  onResize(_webglContainerWidth, _webglContainerHeight, _camera) {
    _camera.aspect = _webglContainerWidth / _webglContainerHeight;

    // image cover
    this.imageAspect = parseInt(this.videoEle.style.height) / parseInt(this.videoEle.style.width);
    let a1; let a2;
    if (_webglContainerHeight / _webglContainerWidth > this.imageAspect) {
      a1 = (_webglContainerWidth / _webglContainerHeight) * this.imageAspect;
      a2 = 1;
    } else {
      a1 = 1;
      a2 = (_webglContainerHeight / _webglContainerWidth) / this.imageAspect;
    }

    this.mesh.material.uniforms.u_resolution.value.x = _webglContainerWidth;
    this.mesh.material.uniforms.u_resolution.value.y = _webglContainerHeight;
    this.mesh.material.uniforms.u_resolution.value.z = a1;
    this.mesh.material.uniforms.u_resolution.value.w = a2;

    const dist = _camera.position.z;
    const height = 1;
    _camera.fov = 2 * (180 / Math.PI) * Math.atan(height / (2 * dist));

    this.mesh.scale.x = _camera.aspect;
    this.mesh.scale.y = 1;

    _camera.updateProjectionMatrix();
  }


  update(_dt) {
    if (store.getters.keyWIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 0;
      this.post.bloomPass.threshold = 0.80;
      this.post.bloomPass.strength = 1.0;
      this.post.bloomPass.radius = -1;
    }

    if (store.getters.keyAIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 1;
      this.post.bloomPass.threshold = 0.0;
      this.post.bloomPass.strength = 0.0;
      this.post.bloomPass.radius = 0;
    }

    if (store.getters.keySIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 2;
      this.post.bloomPass.threshold = 0.0;
      this.post.bloomPass.strength = 0.0;
      this.post.bloomPass.radius = 0;
    }

    if (store.getters.keyDIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 3;
      this.post.bloomPass.threshold = 0.0;
      this.post.bloomPass.strength = 0.0;
      this.post.bloomPass.radius = 0;
    }

    if (this.videoIsReady) {
      this.activeRatio += _dt * this.activeRatioSpeed;

      this.mesh.material.uniforms.u_activeRatio.value = Helpers.clamp(
        this.activeRatio,
        0.0,
        1.0
      );
    }

    this.mesh.material.uniforms.u_time.value += _dt;
  }

}

export default new Webcam();