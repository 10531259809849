import * as THREE from "three";
import gsap from "gsap";

export const hexToGLRgb = (_hexStr) => {
  //check if valid hex value
  if (/^#([0-9A-F]{3}){1,2}$/i.test(_hexStr)) {
    let col = new THREE.Color(_hexStr);
    let out = col.toArray().map((x) => {
      //to fixed 3
      let conv = Math.round(x * 1000) / 1000;
      //append missing periods
      if (conv.toString().indexOf(".") === -1) conv += ".";
      return conv;
    });
    return {
      r: parseFloat(out[0]),
      g: parseFloat(out[1]),
      b: parseFloat(out[2]),
    };
  } else {
    return "";
  }
};

export const toggleUserInput = (_bool) => {
  if (_bool === true) {
    document.body.style.pointerEvents = "auto";
  } else {
    document.body.style.pointerEvents = "none";
  }
};

export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

//Sets _string to lowercase, delete "_", delete " ".
export const normalizeString = (_string) => {
  if (!_string) return;
  return _string.toLowerCase().replace("_", "").replace(/\s+/g, "");
};

//Sets _string by replacing "_" with " " space.
export const removeUnderscores = (_string) => {
  if (!_string) return;
  return _string.replace("_", " ");
};

export const getObjectSize = (_object) => {
  const objectBoundingBox = new THREE.Box3().setFromObject(_object);
  const objectSize = objectBoundingBox.getSize(new THREE.Vector3());
  return objectSize;
};

export const getNormalizedYRotRadians = (_object) => {
  const totalYRotationsInRad = _object.rotation.y / THREE.Math.degToRad(360);
  const yRotationDecimal =
    totalYRotationsInRad - Math.floor(totalYRotationsInRad);
  const rotationDecimalInDeg = THREE.Math.radToDeg(
    yRotationDecimal * (Math.PI * 2)
  );
  return THREE.Math.degToRad(rotationDecimalInDeg);
};

export const getPosByScreenCoords = (
  _camera,
  _appContainer,
  _screenCoordX,
  _screenCoordY,
  _targetZ
) => {
  let vec = new THREE.Vector3(); // create once and reuse
  let pos = new THREE.Vector3(); // create once and reuse
  let distance;

  vec.set(
    (_screenCoordX / _appContainer.clientWidth) * 2 - 1,
    -(_screenCoordY / _appContainer.clientHeight) * 2 + 1,
    0.5
  );

  vec.unproject(_camera);

  vec.sub(_camera.position).normalize();
  if (_targetZ) {
    distance = (_targetZ - _camera.position.z) / vec.z;
  } else {
    distance = -_camera.position.z / vec.z;
  }

  pos.copy(_camera.position).add(vec.multiplyScalar(distance));

  return pos;
};

export const calculateUnitSize = (_camera, _appContainer, _distance) => {
  const vFov = (_camera.fov * Math.PI) / 180;
  const height = 2 * Math.tan(vFov / 2) * _distance;
  const width =
    height * (_appContainer.clientWidth / _appContainer.clientHeight);
  return {
    width,
    height,
  };
};

const cleanMaterial = (_material) => {
  _material.dispose();
  // dispose textures
  for (const key of Object.keys(_material)) {
    const value = _material[key];
    if (value && typeof value === "object" && "minFilter" in value) {
      value.dispose();
    }
  }
};

export const logRendererInfo = (_renderer) => {
  console.log(
    "[Helpers] rendererinfo: ",
    " Geometries: ",
    _renderer.info.memory.geometries,
    " Textures: ",
    _renderer.info.memory.textures,
    " Programs: ",
    _renderer.info.programs.length,
    " calls: ",
    _renderer.info.render.calls
  );
};

export const logAmountOfDetailSceneObjects = (_scene) => {
  let meshCount = 0;
  let groupCount = 0;
  let object3DCount = 0;
  if (!_scene) return;
  _scene.traverse((_object) => {
    if (_object.isMesh) {
      meshCount++;
    } else if (_object.isGroup) {
      groupCount++;
    } else if (_object.isObject3D) {
      object3DCount++;
    }
  });
  console.log(
    "[Helpers] logAmountOfDetailSceneObjects scene:",
    _scene.name,
    " MeshCount: ",
    meshCount,
    " GroupCount: ",
    groupCount,
    " Object3Dcount: ",
    object3DCount
  );
};

export const destroyScene = async (_scene) => {
  return new Promise((_resolve) => {
    //Clean scene
    _scene.traverse((_object) => {
      if (_object.isMesh) {
        _object.geometry.dispose();
        if (_object.material.isMaterial) {
          cleanMaterial(_object.material);
        } else {
          // an array of materials
          for (let i = 0; i < _object.material.length; i++) {
            cleanMaterial(_object.material[i]);
          }
        }
      } else if (_object.isGroup || _object.isObject3D) {
        _object = null;
      }
    });

    while (_scene.children.length > 0) {
      _scene.remove(_scene.children[0]);
    }

    return _resolve();
  });
};

export const isEven = (_value) => {
  return _value % 2 == 0;
};

export const getRandomFloat = (_min, _max) => {
  return Math.random() * (_max - _min) + _min;
};

export const triggerWindowResize = () => {
  let event;
  if (typeof Event === "function") {
    event = new Event("resize");
  } else {
    /*IE*/
    event = document.createEvent("Event");
    event.initEvent("resize", true, true);
  }
  window.dispatchEvent(event);
};

export const fadeMaterialTransparency = async (
  _material,
  _value,
  _tweenTime
) => {
  gsap.to(_material, { opacity: _value, duration: _tweenTime });
};

export const fadeColor = async (
  _material,
  _newRGBColor,
  _tweenTime,
  _delayVal
) => {
  return new Promise((_resolve) => {
    let initialColor = new THREE.Color(_material.color.getHex());
    let newColor = new THREE.Color(_newRGBColor);

    gsap.to(initialColor, {
      delay: _delayVal,
      r: newColor.r,
      g: newColor.g,
      b: newColor.b,
      duration: _tweenTime,
      //ease: Power2.easeInOut,
      onUpdate: function () {
        _material.color = initialColor;
      },
      onComplete: _resolve,
    });
  });
};


export const getNormalizedPointerPos = (_event, _webglContainer) => {
  return {
    x: ((_event.clientX / _webglContainer.clientWidth) * 2 - 1),
    y: (-(_event.clientY / _webglContainer.clientHeight) * 2 + 1)
  }
}


export const throttle = (func, wait) => {
  var context, args, result;
  var timeout = null;
  var previous = 0;

  var later = function () {
    previous = Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function () {
    var now = Date.now();
    if (!previous) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};



export function clamp(val, min, max) {
  return val < min ? min : val > max ? max : val;
}

export function saturate(val) {
  return clamp(val, 0, 1);
}


export function minTwoDigits(n) {
  return (n < 10 ? '0' : '') + n;
}

export const shoutout = () => {
  const chromeStyles =
    "font-size       : 52px;" +
    "color           : transparent;" +
    "line-height     : 60px;" +
    'background-image: url("https://dget4nqgz2ftf.cloudfront.net/gifs/freddy.gif");' +
    "background-size : cover;";
  const safariStyles =
    "font-size          : 40px;" +
    "color              : transparent;" +
    "line-height        : 80px;" +
    'background-image   : url("https://dget4nqgz2ftf.cloudfront.net/gifs/freddy.gif");' +
    "background-size    : cover;" +
    "background-position: center center;";
  const firefoxStyles =
    "font-size          : 14px;" +
    "color              : #ff60e2;" +
    "line-height        : 20px;";
  const homiesLog = "and have a nice day. 🍺 ";
  // const chrLogTxt = "Made with" + " 🍺 and 🦄 " + "by";
  // const safLogTxt = "Made with" + " 🍺 and 🦄 " + "by";
  // const ffxLogTxt = `${"Made with" + " 🍺 and 🦄 " + "by Dmdrn "}${homiesLog}`;

  const chrLogTxt = "Cheers from Cologne";
  const safLogTxt = "Cheers from Cologne";
  const ffxLogTxt = `${"Cheers from Cologne "}${homiesLog}`;

  if (navigator.userAgent.indexOf("Chrome") !== -1) {
    console.log(`${chrLogTxt} %c..`, chromeStyles, homiesLog);
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    console.log(`${safLogTxt}   %c.....`, safariStyles);
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    console.log(`%c${ffxLogTxt}`, firefoxStyles);
  }
};
