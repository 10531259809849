import * as THREE from "three";

export default class AppEngine {
  constructor(_appContainer, _systemInfo) {
    this.appContainer = _appContainer;
    this.systemInfo = _systemInfo;
    this.camera = this.initCamera(this.appContainer);
    this.renderer = this.initRenderer(this.appContainer, this.systemInfo);
    this.clock = new THREE.Clock();

    return this;
  }

  initCamera(_appContainer) {
    const camera = new THREE.PerspectiveCamera(
      70,
      _appContainer.clientWidth / _appContainer.clientHeight,
      0.001,
      1000
    );

    camera.calculateUnitSize = (distance = this.position.z) => {
      const vFov = (camera.fov * Math.PI) / 180;
      const height = 2 * Math.tan(vFov / 2) * distance;
      const width =
        height * (_appContainer.clientWidth / _appContainer.clientHeight);
      return {
        width,
        height,
      };
    };
    window.appCamera = camera;
    return camera;
  }

  initRenderer(_appContainer, _systemInfo) {
    //Turn of antialias - post FXAA handles that
    const renderer = new THREE.WebGLRenderer({
      powerPreference: 'high-performance',
      antialias: false,
      stencil: false, //on post
      depth: false, //on post
      alpha: false,
    });

    //For alpha bg
    //renderer.setClearColor(0x000000, 0)
    renderer.isEnabled = false;
    //renderer.physicallyCorrectLights = true;
    // renderer.toneMapping = THREE.CineonToneMapping;
    // renderer.toneMappingExposure = 2;
    //renderer.shadowMap.autoUpdate = false;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.autoUpdate = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
    renderer.isWebGL2 = renderer.capabilities.isWebGL2;
    renderer.maxAnisotropy = renderer.capabilities.getMaxAnisotropy();

    //renderer.outputEncoding = THREE.sRGBEncoding;
    /**Renderer should have .outputEncoding = sRGBEncoding if no post-processing is used, otherwise use LinearEncoding and apply gamma correction (TBD) as last pass in post instead */
    renderer.setSize(_appContainer.clientWidth, _appContainer.clientHeight);
    //renderer.debug.checkShaderErrors = false // TODO check for prod maybe worth it?? https://threejs.org/docs/#api/en/renderers/WebGLRenderer.checkShaderErrors
    /**
     * Set low dpr for devices with x2 due perf and
     * fix to x2  dpr for devices with bigger dpr than x2.
     */
    renderer.autoClear = false;
    const dpr = _systemInfo.dpr < 2 ? 1.5 : 2.0;
    renderer.setPixelRatio(dpr);
    _appContainer.appendChild(renderer.domElement);
    return renderer;
  }

  toggleShadows(_bool) {
    this.renderer.shadowMap.enabled = _bool;
    this.renderer.shadowMap.autoUpdate = _bool;
  }

  render(_scene, _camera) {
    this.renderer.render(_scene, _camera);
  }

  stopRenderer() {
    this.renderer.isEnabled = false;
  }

  startRenderer() {
    this.renderer.isEnabled = true;
  }

  compile(_scene, _camera) {
    this.renderer.compile(_scene, _camera);
  }

  getDelta() {
    return this.clock.getDelta();
  }

  getElapsedTime() {
    return this.clock.getElapsedTime();
  }

  onResize(_width, _height) {
    this.renderer.setSize(_width, _height);
    this.camera.aspect = _width / _height;
    this.camera.updateProjectionMatrix();
  }
}
