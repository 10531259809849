/* eslint-disable no-unused-vars */
import store from "@/store.js";
import Scene0 from "../scenes/scene0/scene0";
import Scene1 from "../scenes/scene1/scene1.js";
import Scene2 from "../scenes/scene2/scene2.js";
import Scene3 from "../scenes/scene3/scene3.js";
import Scene4 from "../scenes/scene4/scene4.js";
import Scene5 from "../scenes/scene5/scene5.js";
import Scene6 from "../scenes/scene6/scene6.js";
import Scene7 from "../scenes/scene7/scene7";


export default class SceneManager {
  constructor(_appEngine, _webglContainer, _systemInfo) {
    this.activeSceneIndex = store.getters.activeSceneIndex();
    this.nextActiveSceneIndex = 0;
    this.scenes = this.init(_appEngine, _webglContainer, _systemInfo);
    this.timeToSleepInSecs = 120;
    this.prevElapsedTime = this.timeToSleepInSecs;
    this.isInStandBy = false;
  }

  init(_appEngine, _webglContainer, _systemInfo) {
    const array = [];
    array.push(new Scene0(_appEngine, _webglContainer, _systemInfo));
    array.push(new Scene1(_appEngine, _webglContainer, _systemInfo));
    array.push(new Scene2(_appEngine, _webglContainer, _systemInfo));
    array.push(new Scene3(_appEngine, _webglContainer, _systemInfo));
    array.push(new Scene4(_appEngine, _webglContainer, _systemInfo));
    array.push(new Scene5(_appEngine, _webglContainer, _systemInfo));
    array.push(new Scene6(_appEngine, _webglContainer, _systemInfo));
    array.push(new Scene7(_appEngine, _webglContainer, _systemInfo));



    return array;
  }


  showNextScene() {
    let currentActive = this.activeSceneIndex;
    this.nextActiveSceneIndex = (currentActive++ >= this.scenes.length - 1) ? 0 : currentActive;
    this.checkAndToggleIfSceneNeedsWebcam();
    store.actions.setIsSceneChanging(true);
    store.getters.socketInstance().emit('onActiveSceneChanged', this.nextActiveSceneIndex);
  }


  showPrevScene() {
    let currentActive = this.activeSceneIndex;
    this.nextActiveSceneIndex = (currentActive-- <= 0) ? this.scenes.length - 1 : currentActive;
    this.checkAndToggleIfSceneNeedsWebcam();
    store.actions.setIsSceneChanging(true);
    store.getters.socketInstance().emit('onActiveSceneChanged', this.nextActiveSceneIndex);
  }


  onShowScene(index) {
    if (index === this.nextActiveSceneIndex) return;
    this.nextActiveSceneIndex = index;
    this.checkAndToggleIfSceneNeedsWebcam();
    store.actions.setIsSceneChanging(true);
  }


  onResize(_webglContainerWidth, _webglContainerHeight) {
    this.scenes.forEach(_scene => {
      _scene.onResize(_webglContainerWidth, _webglContainerHeight);
    });
  }


  checkAndToggleIfSceneNeedsWebcam() {
    if (this.scenes[this.activeSceneIndex].hasWebcam) {
      this.scenes[this.activeSceneIndex].stopWebcam();
    }

    if (this.scenes[this.nextActiveSceneIndex].hasWebcam) {
      this.scenes[this.nextActiveSceneIndex].startWebcam();
    }
  }



  updatedStandBy(_dt, _elapsedTime) {
    if (this.isInStandBy) {
      if (store.getters.keyWIsDown() || store.getters.keyAIsDown() || store.getters.keySIsDown() || store.getters.keyDIsDown()) {
        this.prevElapsedTime = _elapsedTime;
        this.isInStandBy = false;
      }
    } else {
      if (_elapsedTime - this.prevElapsedTime > this.timeToSleepInSecs) {
        this.onShowScene(0);
        this.isInStandBy = true;
      }
    }
  }



  animate(_dt, _elapsedTime) {
    if (store.getters.isSceneChanging()) {
      // this.scenes[this.activeSceneIndex].animate(_dt);
      // this.scenes[this.nextActiveSceneIndex].animate(_dt);

      // Render them piong-pong to save some perf
      // !! Will also divide frames/speed = slowing down scene refresh rate
      if (this.scenes[this.activeSceneIndex].hasRendered === false) {
        this.scenes[this.activeSceneIndex].animate(_dt);
        this.scenes[this.activeSceneIndex].hasRendered = true;
      } else {
        this.scenes[this.nextActiveSceneIndex].animate(_dt);
        this.scenes[this.nextActiveSceneIndex].hasRendered = true;
        this.scenes[this.activeSceneIndex].hasRendered = false;
      }

    } else {
      this.scenes[this.activeSceneIndex].animate(_dt);
      this.activeSceneIndex = this.nextActiveSceneIndex;
      store.actions.setActiveSceneIndex(this.activeSceneIndex);
    }

    this.updatedStandBy(_dt, _elapsedTime);
  }
}