import store from "@/store.js";
import * as THREE from "three";
import fsQuad_vertex from "./shaders/vertex.glsl";
import fsQuad_fragment from "./shaders/fragment.glsl";
export default class FsQuad {
  constructor(_sceneManager) {
    this.sceneManager = _sceneManager;
    this.assets = store.getters.assets();
    this.mesh = this.init();

    return this;
  }

  init() {
    const rtTexture1 = this.sceneManager.scenes[this.sceneManager.activeSceneIndex].post.composer.renderTarget2.texture;

    const rtTexture2 = this.sceneManager.scenes[this.sceneManager.nextActiveSceneIndex].post.composer.renderTarget2.texture;

    // const webcamTexture = new THREE.VideoTexture(store.getters.webcamDIV());
    // webcamTexture.minFilter = THREE.LinearFilter;
    // webcamTexture.magFilter = THREE.LinearFilter;
    // webcamTexture.format = THREE.RGBAFormat;

    const material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      uniforms: {
        u_time: { value: 0.0 },
        u_progress: { value: 0.0 },
        u_border: { value: 1.0 },
        u_scaleX: { value: 2.0 },
        u_scaleY: { value: 2.0 },
        u_width: { value: 1.0 },
        u_opacity: { value: 1.0 },
        u_rtTexture1: { value: rtTexture1 },
        u_rtTexture2: { value: rtTexture2 },
        u_discardTexture: { value: this.assets.testc },
        u_discardTextureResolution: { value: new THREE.Vector4() },
        //u_webcamTexture: { value: webcamTexture },
        u_maskColor: { value: new THREE.Color('#ff00ff') },
        u_displacement: { value: this.assets.displ },
        u_resolution: { value: new THREE.Vector4() },
        u_maskResolution: { value: new THREE.Vector4() },
      },
      vertexShader: fsQuad_vertex,
      fragmentShader: fsQuad_fragment,
    });

    const geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1);
    const mesh = new THREE.Mesh(geometry, material);

    return mesh;
  }


  onResize(_webglContainerWidth, _webglContainerHeight, _camera) {
    //const imageAspect = _webglContainerHeight / _webglContainerWidth;
    const discardImageAspect = this.mesh.material.uniforms.u_discardTexture.value.image.height / this.mesh.material.uniforms.u_discardTexture.value.image.width;

    let a1;
    let a2;
    if (_webglContainerHeight / _webglContainerWidth > discardImageAspect) {
      a1 = (_webglContainerWidth / _webglContainerHeight) * discardImageAspect;
      a2 = 1;
    } else {
      a1 = 1;
      a2 = (_webglContainerHeight / _webglContainerWidth) / discardImageAspect;
    }
    //used for post target. Res gots defined in scene resize. So no need to check aspect
    this.mesh.material.uniforms.u_discardTextureResolution.value.x = _webglContainerWidth;
    this.mesh.material.uniforms.u_discardTextureResolution.value.y = _webglContainerHeight;
    this.mesh.material.uniforms.u_discardTextureResolution.value.z = a1;
    this.mesh.material.uniforms.u_discardTextureResolution.value.w = a2;

    this.mesh.material.uniforms.u_maskResolution.value.x = _webglContainerWidth;
    this.mesh.material.uniforms.u_maskResolution.value.y = _webglContainerHeight;
    this.mesh.material.uniforms.u_maskResolution.value.z = a1;
    this.mesh.material.uniforms.u_maskResolution.value.w = a2;

    const dist = _camera.position.z;
    const height = 1;
    _camera.fov = 2 * (180 / Math.PI) * Math.atan(height / (2 * dist));

    this.mesh.scale.x = _camera.aspect;
    this.mesh.scale.y = 1;
  }



  // eslint-disable-next-line no-unused-vars
  animate(_dt) {
    //console.log(_dt)
    // let rtTexture1 = this.sceneManager.scenes[this.sceneManager.activeSceneIndex].post.composer.renderTarget2.texture;
    // let rtTexture2 = this.sceneManager.scenes[this.sceneManager.nextActiveSceneIndex].post.composer.renderTarget2.texture;

    this.mesh.material.uniforms.u_rtTexture1.value = this.sceneManager.scenes[this.sceneManager.activeSceneIndex].post.composer.renderTarget2.texture;
    this.mesh.material.uniforms.u_rtTexture2.value = this.sceneManager.scenes[this.sceneManager.nextActiveSceneIndex].post.composer.renderTarget2.texture;


    if (store.getters.isSceneChanging()) {
      this.mesh.material.uniforms.u_progress.value = store.getters.sceneTransitionRatio();
    }



    //this.pageTransitionRatio = Helpers.clamp(this.pageTransitionRatio + dt * this.pageTransitionSpeed, 0, 1);

    // console.log(dt, dt * this.pageTransitionSpeed, this.pageTransitionRatio + (dt * this.pageTransitionSpeed))


    //this.isPageTransitionActive = this.pageTransitionRatio !== 1;











  }
}