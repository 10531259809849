import store from "@/store.js";
import * as THREE from "three";
import titleVert from './titleVert.vert';
import titleFrag from './titleFrag.frag';


class Title2 {
  constructor() {
    this.container = new THREE.Object3D();
    this.mesh = null;
    this.texture = null;
  }

  init() {
    const assets = store.getters.assets();

    this.texture = assets.pressstart;
    this.texture.flipY = true;
    this.texture.wrapS = this.texture.wrapT = THREE.RepeatWrapping;

    const geometry = new THREE.PlaneGeometry(15, 15, 1, 1);

    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_time: { value: 0.0 },
        u_texture: { value: this.texture }
      },
      vertexShader: titleVert,
      fragmentShader: titleFrag,
      transparent: true,
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.container.add(this.mesh);
  }

  update(_dt) {
    this.mesh.material.uniforms.u_time.value += _dt;
  }
}

export default new Title2();

