import store from "@/store.js";
import * as THREE from "three";
import CustomMaterial from "./materials/CustomMaterial";
import * as Helpers from '@/utils/helpers.js';

class Flowmap {
  constructor() {
    this.container = new THREE.Object3D();
    this.mesh = null;

    this.activeRatioSpeed = 1.0;
    this.keyWActiveRatio = 0;
    this.keyAActiveRatio = 0;
    this.keySActiveRatio = 0;
    this.keyDActiveRatio = 0;
  }

  init() {
    const assets = store.getters.assets();

    const diffuseTexture = assets.granitediffuse;
    diffuseTexture.wrapS = diffuseTexture.wrapT = THREE.RepeatWrapping;

    const flowTexture = assets.flowmapx;

    const flowNormalTexture = assets.granitenormal;
    flowNormalTexture.wrapS = flowNormalTexture.wrapT = THREE.RepeatWrapping;

    const geometry = new THREE.PlaneGeometry(10, 10);

    const material = new CustomMaterial({
      diffuseTexture: diffuseTexture,
      flowUvJump: new THREE.Vector2(0, 0), //0.24, 0.2083333
      textureTiling: 2.0,
      flowSpeed: 0.15,
      flowStrength: 0.125,
      flowOffset: -0.5,
      flowTexture: flowTexture,
      flowNormalTexture: flowNormalTexture,
      normalScale: 100.0,
      lightPosition: new THREE.Vector3(0, 1.0, 0)
    });

    this.mesh = new THREE.Mesh(geometry, material);
    this.mesh.rotation.x = -Math.PI / 2;
    this.container.add(this.mesh);


    // const geometry2 = new THREE.BoxGeometry(1, 1, 1);
    // const material2 = new THREE.MeshPhongMaterial({ color: '#0c308e' });
    // this.cube = new THREE.Mesh(geometry2, material2);
    // this.cube.position.y = 1;
    // this.container.add(this.cube);
  }

  onPointerdown() {
    this.isDown = true;
  }

  onPointerup() {
    this.isDown = false;
  }

  clamp(val, min, max) {
    return val < min ? min : val > max ? max : val;
  }

  saturate(val) {
    return this.clamp(val, 0, 1);
  }

  update(_dt) {
    this.keyWActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keyWActiveRatio + (store.getters.keyWIsDown() ? _dt : -_dt) / this.activeRatioSpeed),
      -0.50,
      0.50
    );

    this.keyAActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keyAActiveRatio + (store.getters.keyAIsDown() ? _dt : -_dt) / this.activeRatioSpeed),
      -0.50,
      0.50
    );


    this.keySActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keySActiveRatio + (store.getters.keySIsDown() ? _dt : -_dt) / this.activeRatioSpeed),
      -0.50,
      0.50
    );

    this.keyDActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keyDActiveRatio + (store.getters.keyDIsDown() ? _dt : -_dt) / this.activeRatioSpeed),
      -0.50,
      0.50
    );

    this.mesh.material.uniforms.u_time.value += _dt;

    this.mesh.material.uniforms.u_flowOffset.value = 0.5 + this.keyDActiveRatio + this.keyAActiveRatio + this.keySActiveRatio + this.keyWActiveRatio;

    this.mesh.material.uniforms.u_lightPosition.value.x = this.keyDActiveRatio - this.keyAActiveRatio;
    this.mesh.material.uniforms.u_lightPosition.value.y = this.keySActiveRatio - this.keyWActiveRatio;

    // this.cube.position.x = this.mesh.material.uniforms.u_lightPosition.value.x;
    // this.cube.position.z = this.mesh.material.uniforms.u_lightPosition.value.y;

  }
}

export default new Flowmap();
