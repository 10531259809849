/* eslint-disable no-unused-vars */
import store from "@/store.js";
import * as THREE from "three";
import Postprocess from "./postprocess.js";
import flowmap from "./flowmap/flowmap.js";


export default class Scene6 {
  // eslint-disable-next-line no-unused-vars
  constructor(_appEngine, _webglContainer, _systemInfo) {
    this.isDebugActive = window.location.hash === "#debug" ? true : false;
    this.hasRendered = false;
    this.appEngine = _appEngine;
    this.assets = store.getters.assets();
    this.camera = this.initCamera(_webglContainer);
    this.scenes = this.initScenes(_webglContainer);
    this.post = this.initPost(_appEngine, _webglContainer, this.scenes, this.camera, _systemInfo, this.datGUI);
    this.onResize(_webglContainer.clientWidth, _webglContainer.clientHeight, this.appEngine.camera);
    this.activeRatioSpeed = 1.0;
    this.keyWActiveRatio = 0;
    this.keyAActiveRatio = 0;
    this.keySActiveRatio = 0;
    this.keyDActiveRatio = 0;
    return this;
  }


  initCamera(_webglContainer) {
    const camera = new THREE.PerspectiveCamera(
      70,
      _webglContainer.clientWidth / _webglContainer.clientHeight,
      0.1,
      50
    );

    camera.position.set(0, 2.5, 0);
    camera.lookAt(new THREE.Vector3(0, 0, 0));

    window.testscene3camera = camera;
    return camera;
  }


  initScenes(_webglContainer) {
    const sceneA = new THREE.Scene();//Bloom scene
    sceneA.background = new THREE.Color('#fefefe')

    const sceneB = new THREE.Scene();

    flowmap.init();
    sceneA.add(flowmap.container);

    return { sceneA, sceneB };
  }


  initPost(_appEngine, _webglContainer, _scenes, _camera, _datGUI) {
    const { sceneA, sceneB } = _scenes;

    const post = new Postprocess(
      _appEngine,
      _webglContainer,
      sceneA,
      sceneB,
      _camera,
      _datGUI
    );


    return post;
  }


  onResize(_webglContainerWidth, _webglContainerHeight) {
    this.camera.aspect = _webglContainerWidth / _webglContainerHeight;
    this.camera.updateProjectionMatrix();
    this.post.onResize(_webglContainerWidth, _webglContainerHeight);
  }

  animate(_dt) {
    flowmap.update(_dt);
    this.post.render();
  }
}