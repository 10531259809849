/* eslint-disable no-unused-vars */
import store from "@/store.js";
import * as THREE from "three";
import Postprocess from "./postprocess.js";
//import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
//import { GUI } from "three/examples/jsm/libs/dat.gui.module.js";
import * as Helpers from '@/utils/helpers.js';
import { OrbitControls } from "../../../../utils/OrbitControls.js";
import remote from "./remote/remote.js";
import title from "./title/title.js";
import title2 from "./title2/title2.js";
import room from "./room/room.js";

export default class Scene0 {
  constructor(_appEngine, _webglContainer, _systemInfo) {
    this.isDebugActive = window.location.hash === "#debug" ? true : false;
    this.hasRendered = false;
    this.appEngine = _appEngine;
    this.assets = store.getters.assets();
    this.camera = this.initCamera(_webglContainer);
    this.scenes = this.initScenes(_webglContainer);
    this.post = this.initPost(_appEngine, _webglContainer, this.scenes, this.camera, _systemInfo, this.datGUI);
    this.onResize(_webglContainer.clientWidth, _webglContainer.clientHeight, this.appEngine.camera);
  }



  initCamera(_webglContainer) {

    const camera = new THREE.PerspectiveCamera(
      70,
      _webglContainer.clientWidth / _webglContainer.clientHeight,
      0.1,
      50
    );
    //is windows pos z 6.5 else 10
    camera.position.set(0, 0, 5);
    window.scene0Camera = camera;
    return camera;
  }



  initScenes(_webglContainer) {
    const sceneA = new THREE.Scene();
    sceneA.background = new THREE.Color('#000000');

    sceneA.fog = new THREE.FogExp2('#ff0000', 0.00);
    window.fog = sceneA.fog;

    const sceneB = new THREE.Scene();

    remote.init();
    sceneB.add(remote.container);

    title.init();
    sceneA.add(title.container);

    title2.init();
    sceneB.add(title2.container);

    room.init();
    sceneA.add(room.container);

    const directionalLight = new THREE.DirectionalLight('#e0b826', 5.25);
    directionalLight.position.set(0, -3, 2.5);
    sceneB.add(directionalLight);


    const ambientLight = new THREE.AmbientLight('#010101', 100);
    sceneB.add(ambientLight);

    this.orbitControls = new OrbitControls(this.camera, _webglContainer);

    return { sceneA, sceneB };
  }

  initPost(_appEngine, _webglContainer, _scenes, _camera, _datGUI) {
    const { sceneA, sceneB } = _scenes;

    const post = new Postprocess(
      _appEngine,
      _webglContainer,
      sceneA,
      sceneB,
      _camera,
      _datGUI
    );

    return post;
  }


  onResize(_webglContainerWidth, _webglContainerHeight) {
    this.camera.aspect = _webglContainerWidth / _webglContainerHeight;
    this.camera.updateProjectionMatrix();
    this.post.onResize(_webglContainerWidth, _webglContainerHeight);
  }

  animate(_dt) {
    title.update(_dt);
    title2.update(_dt);
    remote.update(_dt);

    this.post.render();
  }
}