/* eslint-disable max-len */
/* eslint-disable function-paren-newline */
import gsap from "gsap";
import { Vector2 /*Color, LinearFilter, WebGLRenderTarget*/ } from "three";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
//import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
//import { BokehPass } from "three/examples/jsm/postprocessing/BokehPass.js";
//import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";

//import { BlendShader } from 'three/examples/jsm/shaders/BlendShader.js';
//import { SavePass } from 'three/examples/jsm/postprocessing/SavePass.js';
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js';
//import GlowsPass from './glow/glow.js'
//import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass.js';
//import { HalfFloatType } from "three";

export default class Postprocess {
  constructor(_appEngine, _container, _sceneA, _sceneB, _camera, _systemInfo, _datGUI) {
    this.renderer = _appEngine.renderer;
    this.camera = _camera;
    this.container = _container;
    this.sceneA = _sceneA;
    this.sceneB = _sceneB;
    //this.systemInfoDpr = _systemInfo.dpr < 2 ? 1.5 : 1;
    this.containerWidth = this.container.clientWidth;
    this.containerHeight = this.container.clientHeight;
    this.bloomPass = null;
    this.effectVignette = null;
    this.effectFXAA = null;
    this.init();
    window.ff = this.renderer;
    // Debug
    if (_datGUI) {
      this.initDatGUI(_datGUI);
    }
  }

  init() {
    // first, create the composer, which will combine the shader effects
    this.composer = new EffectComposer(this.renderer);
    //this.composer.frameBufferType = HalfFloatType; for srgb check //https://github.com/vanruesc/postprocessing

    this.sceneAPass = new RenderPass(this.sceneA, this.camera);

    // const bokehPass = new BokehPass(this.sceneA, this.camera, {
    //   focus: 10.0,
    //   aperture: 3,
    //   maxblur: 0.002,
    //   width: this.containerWidth,
    //   height: this.containerHeight,
    // });

    this.composer.addPass(this.sceneAPass);

    // this.outlinePass = new OutlinePass(new Vector2(this.containerWidth, this.containerWidth), this.sceneA, this.camera);
    // this.outlinePass.selectedObjects = this.outlinePassObjects;
    // //this.outlinePass.visibleEdgeColor.set('#ff00ff');
    // this.outlinePass.edgeStrength = 1.5;
    // this.outlinePass.edgeGlow = 1.;
    // this.outlinePass.edgeThickness = 13.15;
    // this.composer.addPass(this.outlinePass);

    // // save pass
    // const renderTargetParameters = {
    //   minFilter: LinearFilter,
    //   magFilter: LinearFilter,
    //   stencilBuffer: false
    // };

    // const savePass = new SavePass(new WebGLRenderTarget(this.containerWidth, this.containerHeight, renderTargetParameters));

    // // blend pass
    // const blendPass = new ShaderPass(BlendShader, 'tDiffuse1');
    // blendPass.uniforms['tDiffuse2'].value = savePass.renderTarget.texture;
    // blendPass.uniforms['mixRatio'].value = 0.5;

    // // output pass
    // const outputPass = new ShaderPass(CopyShader);

    // this.composer.addPass(blendPass);
    // this.composer.addPass(savePass);
    // this.composer.addPass(outputPass);

    // this.verticalBlurPass = new ShaderPass(BlurPass)
    // this.verticalBlurPass.material.uniforms.uResolution.value = new Vector2(256, 256)
    // this.verticalBlurPass.material.uniforms.uStrength.value = new Vector2(0.0, 1.0)
    // this.composer.addPass(this.verticalBlurPass)

    // this.glowsPass = new ShaderPass(GlowsPass)
    // this.glowsPass.color = '#ffe0ff';
    // this.glowsPass.material.uniforms.uPosition.value = new Vector2(0.5, 0.5)
    // this.glowsPass.material.uniforms.uRadius.value = 0.1;
    // this.glowsPass.material.uniforms.uColor.value = new Color(this.glowsPass.color)
    // this.glowsPass.material.uniforms.uAlpha.value = 0.5;
    // window.glow = this.glowsPass;
    // this.composer.addPass(this.glowsPass)
    this.bloomPass = new UnrealBloomPass();
    this.bloomPass.resolution = new Vector2(this.containerWidth, this.containerWidth)
    this.bloomPass.threshold = 0.55;
    this.bloomPass.strength = 0.25;
    this.bloomPass.radius = -0.5;
    window.bloomerc = this.bloomPass;
    // this.bloomPass.threshold = 0.0; 
    // this.bloomPass.strength = 0.5; 
    // this.bloomPass.radius = -0.3;
    this.composer.addPass(this.bloomPass);
    window.bloomPass = this.bloomPass;

    // this.afterimagePass = new AfterimagePass();
    // this.afterimagePass.uniforms.damp.value = 0.0;
    // this.composer.addPass(this.afterimagePass);
    // window.afterimagePass = this.afterimagePass;

    //Add after all other passes so they dont get applied to that sceen.
    // make differebnt scenes. eg.g bloomScene, bloomVignetteScene etc?
    if (this.sceneB) {
      this.sceneBPass = new RenderPass(this.sceneB, this.camera);
      this.sceneBPass.clear = false;
      this.composer.addPass(this.sceneBPass);
    }


    // output pass
    const outputPass = new ShaderPass(CopyShader);
    this.composer.addPass(outputPass);

    this.gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
    this.composer.addPass(this.gammaCorrectionPass);

    // this.effectFXAA = new ShaderPass(FXAAShader);
    // this.effectFXAA.material.uniforms.resolution.value.x =
    //   1 / (this.containerWidth * this.systemInfoDpr);
    // this.effectFXAA.material.uniforms.resolution.value.y =
    //   1 / (this.containerHeight * this.systemInfoDpr);
    // this.composer.addPass(this.effectFXAA);
    // this.effectFXAA.renderToScreen = true;

    //this.composer.addPass(bokehPass);

    // window.addEventListener('touchstart', () => {

    //   this.horizontalBlurPass.strength = 1
    //   this.horizontalBlurPass.material.uniforms.uStrength.value = new Vector2(this.horizontalBlurPass.strength, 10)
    //   this.verticalBlurPass.strength = 1
    //   this.verticalBlurPass.material.uniforms.uStrength.value = new Vector2(10, this.verticalBlurPass.strength)
    // }, { once: true })
  }

  onResize(_width, _height) {
    this.composer.setSize(_width, _height);
    // this.effectFXAA.material.uniforms.resolution.value.x =
    //   1 / (_width * this.systemInfoDpr);
    // this.effectFXAA.material.uniforms.resolution.value.y =
    //   1 / (_height * this.systemInfoDpr);
    // this.horizontalBlurPass.material.uniforms.uResolution.value.x = _width;
    // this.horizontalBlurPass.material.uniforms.uResolution.value.y = _height;
    // this.verticalBlurPass.material.uniforms.uResolution.value.x = _width;
    // this.verticalBlurPass.material.uniforms.uResolution.value.y = _height;
  }

  setBloomThreshold(_value) {
    this.bloomPass.threshold = _value;
  }

  // eslint-disable-next-line no-unused-vars
  onScroll(_trigger) {
    //this.afterimagePass.uniforms.damp.value = 0.1 * (_scrollPosY / 100);
  }

  animateBloom(_strength, _radius) {
    gsap.to(this.bloomPass, {
      strength: _strength,
      radius: _radius,
      duration: 0.25,
    });
  }

  animateVignette(_strength) {
    gsap.to(this.effectVignette.material.uniforms.opacity, {
      value: _strength,
      duration: 0.25,
    });
  }

  animateBlur(_strengthX, _strengthY) {
    gsap.to(this.horizontalBlurPass.material.uniforms.uStrength.value, {
      x: _strengthX,
      y: _strengthY,
      duration: 0.25,
    });
  }

  render() {
    this.composer.render();
  }

  initDatGUI(_datGui) {
    const folderPost = _datGui.addFolder("post" + Math.random());

    //const blurFolder = folderPost.addFolder('blur')
    //blurFolder.add(this.horizontalBlurPass.material.uniforms.uStrength.value, 'x').step(0.001).min(0).max(10);
    // blurFolder.add(this.verticalBlurPass.material.uniforms.uStrength.value, 'y').step(0.001).min(0).max(10);

    const bloomFolder = folderPost.addFolder("bloom");
    bloomFolder
      .add(this.bloomPass, "threshold")
      .step(0.001)
      .min(0)
      .max(3)
      .name("threshold");
    bloomFolder
      .add(this.bloomPass, "strength")
      .step(0.001)
      .min(0)
      .max(3)
      .name("strength");
    bloomFolder
      .add(this.bloomPass, "radius")
      .step(0.001)
      .min(0)
      .max(3)
      .name("radius");

    // const glowFolder = folderPost.addFolder('glow');
    // glowFolder.add(this.glowsPass.material.uniforms.uPosition.value, 'x').step(0.001).min(- 1).max(2).name('positionX');
    // glowFolder.add(this.glowsPass.material.uniforms.uPosition.value, 'y').step(0.001).min(- 1).max(2).name('positionY');
    // glowFolder.add(this.glowsPass.material.uniforms.uRadius, 'value').step(0.001).min(0).max(2).name('radius');
    // glowFolder.addColor(this.glowsPass, 'color').name('color').onChange(() => {
    //   this.glowsPass.material.uniforms.uColor.value = new Color(this.glowsPass.color);
    // })
    // glowFolder.add(this.glowsPass.material.uniforms.uAlpha, 'value').step(0.001).min(0).max(1).name('alpha');
  }
}
