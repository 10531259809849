/* eslint-disable no-unused-vars */
import store from "@/store.js";
import * as THREE from "three";
import AppEngine from "./general/appEngine.js";
import Postprocess from "./general/testPostprocess.js";
//import { GUI } from "three/examples/jsm/libs/dat.gui.module.js";
import Stats from 'three/examples/jsm/libs/stats.module.js';
import FsQuad from "./fsQuad/fsQuad.js";
import SceneManager from "./sceneManager/sceneManager.js";
import * as Helpers from '@/utils/helpers.js'



export default class WebGLApp {
  constructor(_webglContainer, _systemInfo) {
    this.webglContainer = _webglContainer;
    this.appEngine = new AppEngine(_webglContainer, _systemInfo);
    this.appEngine.camera.position.set(0, 0, 1.0);
    this.isDebugActive = window.location.hash === "#debug" ? true : false;
    //this.datGUI = this.isDebugActive ? new GUI({ width: 300 }) : null;
    this.assets = store.getters.assets();
    this.scene = null;
    this.init(_systemInfo);

    return this;
  }

  init(_systemInfo) {
    if (this.isDebugActive) {
      this.stats = new Stats();
      this.webglContainer.appendChild(this.stats.dom);
    }

    this.scene = new THREE.Scene();

    this.sceneManager = new SceneManager(this.appEngine, this.webglContainer, _systemInfo);

    this.fsQuad = new FsQuad(this.sceneManager);
    this.scene.add(this.fsQuad.mesh);

    this.scene.post = new Postprocess(
      this.appEngine,
      this.webglContainer,
      this.scene,
      this.sceneCallmeDifferntman,
      this.appEngine.camera,
      _systemInfo,
      this.datGUI
    );
  }


  showNextScene() {
    this.sceneManager.showNextScene();
  }

  showPrevScene() {
    this.sceneManager.showPrevScene();
  }

  onShowScene(index) {
    this.sceneManager.onShowScene(index);
  }

  // onDestroy() {
  //   this.appEngine.renderer.forceContextLoss();
  //   this.appEngine.renderer.domElement = null;
  //   this.appEngine.renderer = null;
  // }

  onchangeBrighntess(value) {
    this.scene.post.brightness += value;
  }


  onchangeContrast(value) {
    this.scene.post.contrast += value;
  }



  onKeyDown(keyCode) {
    this.sceneManager.onKeyDown(keyCode);
  }

  onResize(_webglContainerWidth, _webglContainerHeight) {
    this.appEngine.onResize(_webglContainerWidth, _webglContainerHeight);
    this.scene.post.onResize(_webglContainerWidth, _webglContainerHeight);
    this.sceneManager.onResize(_webglContainerWidth, _webglContainerHeight);
    this.fsQuad.onResize(_webglContainerWidth, _webglContainerHeight, this.appEngine.camera)
  }


  calculateTransition(_dt) {
    let transitionRatio = Helpers.clamp(store.getters.sceneTransitionRatio() + _dt / store.getters.sceneTransitionDuration(), 0, 1);
    store.actions.setSceneTransitionRatio(transitionRatio);

    if (transitionRatio === 1) {
      store.actions.setIsSceneChanging(false)
      store.actions.setSceneTransitionRatio(0);
    }
  }


  animate() {
    let dt = this.appEngine.getDelta();
    let elpasedTime = this.appEngine.getElapsedTime();

    if (store.getters.isSceneChanging()) {
      this.calculateTransition(dt);
    }

    if (this.isDebugActive) {
      this.stats.update();
    }

    this.fsQuad.animate(dt);

    this.sceneManager.animate(dt, elpasedTime);

    this.scene.post.render();
  }
}