/* eslint-disable no-unused-vars */
import store from "@/store.js";

import * as THREE from "three";
import backgroundVert from './backgroundVert.vert';
import backgroundFrag from './backgroundFrag.frag';

class Background {
  constructor() {
    this.container = new THREE.Object3D();
    this.mesh = null;

    this.colors = {
      top: '#06000f',//#ad76c8',
      bottom: '#06000f',
      fadeout: '#06000f'
    };
  }

  init() {
    const gridTexture = store.getters.assets().grid;
    gridTexture.wrapS = gridTexture.wrapT = THREE.RepeatWrapping;

    const geometry = new THREE.PlaneGeometry(1, 1, 100, 100);
    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_time: { value: 0 },
        u_speed: { value: 1 },
        u_colorA: { value: new THREE.Color(this.colors.top) },
        u_colorB: { value: new THREE.Color(this.colors.bottom) },
        u_colorFadeout: { value: new THREE.Color(this.colors.fadeout) },
        u_gridTexture: { value: gridTexture },
        u_textureRepeat: { value: 10 },
        u_activeIndex: { value: 0 }
      },
      vertexShader: backgroundVert,
      fragmentShader: backgroundFrag,

    });

    this.mesh = new THREE.Mesh(geometry, material);
    //this.mesh.rotation.x = Math.PI / 2;
    this.container.add(this.mesh);
  }



  onResize(_webglContainerWidth, _webglContainerHeight, _camera) {
    this.mesh.scale.x = _webglContainerWidth;
    this.mesh.scale.y = _webglContainerHeight;
  }


  update(_dt, camera) {
    if (store.getters.keyWIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 0;
      this.mesh.material.uniforms.u_colorA.value.setStyle('#06000f');
      this.mesh.material.uniforms.u_colorB.value.setStyle('#06000f');
      this.mesh.material.uniforms.u_colorFadeout.value.setStyle('#06000f');
    }

    if (store.getters.keyAIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 1;
      this.mesh.material.uniforms.u_colorA.value.setStyle('#0c000a');
      this.mesh.material.uniforms.u_colorB.value.setStyle('#0c000a');
      this.mesh.material.uniforms.u_colorFadeout.value.setStyle('#0c000a');
    }

    if (store.getters.keySIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 2;
      this.mesh.material.uniforms.u_colorA.value.setStyle('#00000f');
      this.mesh.material.uniforms.u_colorB.value.setStyle('#00000f');
      this.mesh.material.uniforms.u_colorFadeout.value.setStyle('#00000f');
    }

    if (store.getters.keyDIsDown()) {
      this.mesh.material.uniforms.u_activeIndex.value = 3;
      this.mesh.material.uniforms.u_colorA.value.setStyle('#0c0000');
      this.mesh.material.uniforms.u_colorB.value.setStyle('#0c0000');
      this.mesh.material.uniforms.u_colorFadeout.value.setStyle('#0c0000');
    }

    this.mesh.lookAt(camera.position.clone());
    this.mesh.material.uniforms.u_time.value += _dt * 0.5;
  }
}

export default new Background();