<template>
	<Transition name="fade">
		<LoadingComp
			v-if="isAppSupported() && !loadedAssets"
			@on-loading-finished="onLoadingFinished"
		/>
	</Transition>
	<!-- <WebcamComp @on-webcam-is-ready="onWebcamIsReady" /> -->

	<WebGLComp
		ref="webGLComp"
		v-if="isAppSupported() && loadedAssets /*&& isWebcamReady*/"
		:loadedAssets="loadedAssets"
		:systemInfo="systemInfo"
		@on-webgl-is-ready="onWebGLIsReady"
	/>

	<router-view v-if="isWebGLReady || !isAppSupported()" />
</template>

<script>
import { inject, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import * as Detector from './utils/detector.js';
import LoadingComp from '@/components/loading/Loading_comp.vue';
//import WebcamComp from '@/components/webcam/Webcam_comp.vue';
import WebGLComp from '@/components/webGL/WebGL_comp.vue';

export default {
	components: { LoadingComp, /*WebcamComp,*/ WebGLComp },
	setup() {
		const store = inject('store');
		const router = useRouter();
		const systemInfo = Detector.getSystemInfo();
		const loadedAssets = ref(null);
		//const isWebcamReady = ref(null);
		const isWebGLReady = ref(false);
		const webGLComp = ref(null);

		function isAppSupported() {
			return (
				(systemInfo.isWebGL1 || systemInfo.isWebGL2) &&
				//systemInfo.isSerialAPI &&
				!systemInfo.isIE11
			);
		}

		function onLoadingFinished(_loadedAssets) {
			store.actions.setAssets(_loadedAssets);
			loadedAssets.value = _loadedAssets;
		}

		// function onWebcamIsReady() {
		// 	isWebcamReady.value = true;
		// }

		function onWebGLIsReady() {
			isWebGLReady.value = true;
		}

		onMounted(() => {
			if (!isAppSupported()) router.push({ name: 'NotSupported' });
		});

		return {
			systemInfo,
			isAppSupported,
			onLoadingFinished,
			//onWebcamIsReady,
			onWebGLIsReady,
			loadedAssets,
			webGLComp,
			//isWebcamReady,
			isWebGLReady,
		};
	},
};
</script>

<style lang="scss">
@import '@/styles/styles.scss';

#app {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: &color-black;
	@include hideScrollBar();
}
</style>
