/* eslint-disable no-unused-vars */
import * as THREE from "three";
import player from "../player/player";

import enemyVert from './enemyVert.vert';
import enemyFrag from './enemyFrag.frag';

class Enemies {
  constructor() {
    this.container = new THREE.Object3D();
    this.collection = [];
    this.amount = 10;
  }

  init() {


    for (let i = 0; i < this.amount; i++) {
      const geometry = new THREE.TetrahedronGeometry(3, 0)

      const material = new THREE.ShaderMaterial({
        uniforms: {
          u_color: { value: new THREE.Color(0xffffff * Math.random()) },
          u_time: { value: 0 }
        },
        vertexShader: enemyVert,
        fragmentShader: enemyFrag,
        transparent: true
      });


      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.x = Math.random() * 100 - 50;
      mesh.position.y = 20 + Math.random() * 25 - 12.5;
      mesh.rotation.set(Math.random(), Math.random(), Math.random());
      mesh.userData.rotDirection = Math.random() > 0.5;
      //mesh.scale.set(1 + Math.random(), 1 + Math.random(), 1 + Math.random());
      this.container.add(mesh);
    }

    //this.container.position.y = 20;
  }



  updateMovement(_dt) {
    for (let i = 0; i < this.amount; i++) {
      const mesh = this.container.children[i];
      mesh.position.x -= _dt * 20;

      if (mesh.userData.rotDirection) {
        mesh.rotation.x += _dt * 5;

      } else {
        mesh.rotation.x -= _dt * 5;
      }

      if (mesh.position.x < -45) {
        mesh.position.x = 45 + Math.random() * 10;
        mesh.position.y = 20 + Math.random() * 25 - 12.5;
        mesh.visible = true;
      }

      mesh.material.uniforms.u_time.value += _dt;
    }
  }


  updateCollision() {
    for (let i = 0; i < this.amount; i++) {
      const enemyMesh = this.container.children[i];

      for (let k = 0; k < player.bulletAmount; k++) {
        const bulletMesh = player.containerBullets.children[k];
        if (enemyMesh.position.distanceTo(bulletMesh.position) < 2) {
          enemyMesh.visible = false;
        }
      }
    }
  }

  update(_dt) {
    this.updateMovement(_dt);
    this.updateCollision();
    //this.mesh.material.uniforms.u_time.value += _dt;
  }
}

export default new Enemies();