import shaderFragment from "./rgbSplit.frag";
import shaderVertex from "./rgbSplit.vert";

export const RGBSplit = {
  uniforms: {
    tDiffuse: { value: null },
    u_time: { value: null },
    u_strength: { value: 0.2 },
    u_noiseResolution: { value: 800.0 },
  },
  vertexShader: shaderVertex,
  fragmentShader: shaderFragment,
};
