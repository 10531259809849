/* eslint-disable no-unused-vars */
import store from "@/store.js";
import * as THREE from "three";
import Postprocess from "./postprocess.js";
import * as dat from 'dat.gui';
import { OrbitControls } from "../../../../utils/OrbitControls.js";
import ground from "./ground/ground.js";
import player from "./player/player.js";
import skyline from "./skyline/skyline.js";
import enemies from "./enemies/enemies.js";
import sky from "./sky/sky.js";

export default class Scene1 {
  // eslint-disable-next-line no-unused-vars
  constructor(_appEngine, _webglContainer, _systemInfo) {
    this.hasRendered = false;
    this.appEngine = _appEngine;
    this.assets = store.getters.assets();
    this.camera = this.initCamera(_webglContainer);
    this.scenes = this.initScenes(_webglContainer);
    this.post = this.initPost(_appEngine, _webglContainer, this.scenes, this.camera, _systemInfo, this.datGUI);
    this.onResize(_webglContainer.clientWidth, _webglContainer.clientHeight, this.appEngine.camera);
    //this.animate(1);
    return this;
  }



  initCamera(_webglContainer) {
    const camera = new THREE.PerspectiveCamera(
      70,
      _webglContainer.clientWidth / _webglContainer.clientHeight,
      0.1,
      300
    );
    camera.position.set(0, 4, 32);

    window.scene1Camera = camera;
    return camera;
  }



  initScenes(_webglContainer) {
    const sceneA = new THREE.Scene();//Bloom scene

    const sceneB = new THREE.Scene();

    ground.init();
    sceneA.add(ground.container);

    player.init();
    sceneB.add(player.container);

    enemies.init();
    sceneB.add(enemies.container);

    skyline.init();
    sceneA.add(skyline.container);

    sky.init();
    sceneA.add(sky.container);

    // bridge.init();
    // sceneA.add(bridge.container);


    this.orbitControls = new OrbitControls(this.camera, _webglContainer);
    this.orbitControls.target.set(0, 10, 0);
    return { sceneA, sceneB };
  }


  initPost(_appEngine, _webglContainer, _scenes, _camera, _datGUI) {
    const { sceneA, sceneB } = _scenes;

    const post = new Postprocess(
      _appEngine,
      _webglContainer,
      sceneA,
      sceneB,
      _camera,
      _datGUI
    );

    return post;
  }


  onResize(_webglContainerWidth, _webglContainerHeight) {
    this.camera.aspect = _webglContainerWidth / _webglContainerHeight;
    this.camera.updateProjectionMatrix();

    this.post.onResize(_webglContainerWidth, _webglContainerHeight);
  }


  updateCameraMovement() {
    this.orbitControls.target = player.mesh.position;
    this.camera.position.x = player.mesh.position.x;
    this.camera.position.y = player.mesh.position.y;
    this.camera.lookAt(player.mesh.position);
  }

  animate(_dt) {
    this.orbitControls.update();
    ground.update(_dt);
    player.update(_dt, this.appEngine.getElapsedTime());
    enemies.update(_dt);
    skyline.update(_dt);
    sky.update(_dt);

    this.post.render();
  }
}