/* eslint-disable no-unused-vars */
import * as THREE from "three";
import * as Helpers from '@/utils/helpers.js';
import store from "@/store.js";
import playerVert from './playerVert.vert';
import playerFrag from './playerFrag.frag';

import bulletVert from './bulletVert.vert';
import bulletFrag from './bulletFrag.frag';

import { SkeletonUtils } from "three-stdlib";

class Player {
  constructor() {
    this.container = new THREE.Object3D();
    this.containerBullets = new THREE.Object3D();
    this.meshSpaceship = null;
    this.keyWActiveRatio = 0;
    this.keyAActiveRatio = 0;
    this.keySActiveRatio = 0;
    this.keyDActiveRatio = 0;
    this.activeRatioSpeed = 1.0;
    this.time = 0;
    this.bulletIndex = 0;
    this.bulletAmount = 10;
  }

  init() {
    this.initSpaceship();
    this.initBullets();
  }


  updateMovement(_dt) {
    this.keyWActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keyWActiveRatio + (store.getters.keyWIsDown() ? _dt : -_dt) * this.activeRatioSpeed),
      0.0,
      1.0
    );

    this.keyAActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keyAActiveRatio + (store.getters.keyAIsDown() ? _dt : -_dt) * this.activeRatioSpeed),
      0.0,
      1.0
    );

    this.keySActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keySActiveRatio + (store.getters.keySIsDown() ? _dt : -_dt) * this.activeRatioSpeed),
      0.0,
      1.0
    );

    this.keyDActiveRatio = Helpers.clamp(
      Helpers.saturate(this.keyDActiveRatio + (store.getters.keyDIsDown() ? _dt : -_dt) * this.activeRatioSpeed),
      0.0,
      1.0
    );

    this.meshSpaceship.position.x -= this.keyAActiveRatio;
    this.meshSpaceship.position.x += this.keyDActiveRatio;
    this.meshSpaceship.position.x = Helpers.clamp(this.meshSpaceship.position.x, -32.0, 32.0);

    this.meshSpaceship.position.y -= this.keySActiveRatio * 1.25;
    this.meshSpaceship.position.y += this.keyWActiveRatio * 1.25;
    this.meshSpaceship.position.y = Helpers.clamp(this.meshSpaceship.position.y, 3, 30.0);

    //Rot Up & Down
    this.meshSpaceship.rotation.z = (this.keyWActiveRatio - this.keySActiveRatio);//doewn
    this.meshSpaceship.rotation.z = Helpers.clamp(this.meshSpaceship.rotation.z, -0.25, 0.25);

    //Rot Left & Right
    // this.mesh.rotation.z = (this.keyAActiveRatio - this.keyDActiveRatio);//doewn
    // this.mesh.rotation.z = Helpers.clamp(this.mesh.rotation.z, -0.5, 0.5);
  }

  initSpaceship() {
    const assets = store.getters.assets();

    this.meshSpaceship = SkeletonUtils.clone(assets.arwing.scene);
    this.meshSpaceship.traverse((child) => {
      if (child.isMesh) {
        child.material = new THREE.ShaderMaterial({
          uniforms: {
            u_color: { value: new THREE.Color(0xffffff * Math.random()) },
            u_time: { value: 0.0 },
          },
          vertexShader: playerVert,
          fragmentShader: playerFrag
        });
      }
    });
    this.meshSpaceship.scale.set(5, 5, 5);
    this.container.add(this.meshSpaceship);
  }


  initBullets() {
    const geometry = new THREE.BoxGeometry(4, 0.25, 0.5);
    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_color: { value: new THREE.Color('#4fd0ff') }
      },
      vertexShader: bulletVert,
      fragmentShader: bulletFrag,
    });

    for (let i = 0; i < this.bulletAmount; i++) {
      const cube = new THREE.Mesh(geometry, material);
      cube.position.x = i + 6;
      cube.userData.startPosition = cube.position.clone();
      this.containerBullets.add(cube);
    }

    this.container.add(this.containerBullets);
  }



  updateBullets(_dt, _elapsedTime) {
    this.time += _dt;
    const fireRate = 0.15;

    if (this.time > fireRate) {
      this.time = 0;
      this.bulletIndex++;
      if (this.containerBullets.children[this.bulletIndex]) {
        this.containerBullets.children[this.bulletIndex].position.copy(this.meshSpaceship.position);

      } else {
        this.bulletIndex = 0;
      }
    }

    for (let i = 0; i < this.containerBullets.children.length; i++) {
      const bullet = this.containerBullets.children[i];
      bullet.position.x += _dt * 100;
    }
  }

  update(_dt, _elapsedTime) {
    this.updateMovement(_dt);
    this.updateBullets(_dt, _elapsedTime);
  }
}

export default new Player();