import store from "@/store.js";
import * as THREE from "three";
import Postprocess from "./postprocess.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import particles from "./particles/particles.js";
import background from "./background/background.js";

export default class Scene4 {
  constructor(_appEngine, _webglContainer, _systemInfo) {
    this.isDebugActive = window.location.hash === "#debug" ? true : false;
    this.hasRendered = false;
    this.appEngine = _appEngine;
    this.assets = store.getters.assets();
    this.camera = this.initCamera(_webglContainer);
    this.scenes = this.initScenes(_webglContainer);
    this.post = this.initPost(_appEngine, _webglContainer, this.scenes, this.camera, _systemInfo, this.datGUI);
    this.onResize(_webglContainer.clientWidth, _webglContainer.clientHeight, this.appEngine.camera);
    this.activeRatioSpeed = 1.0;
    this.activeRatio = 0;
    this.counter = 0;
    this.amountOfVersions = 11;
    this.isDown = false;
    return this;
  }



  initCamera(_webglContainer) {
    const camera = new THREE.PerspectiveCamera(
      70,
      _webglContainer.clientWidth / _webglContainer.clientHeight,
      0.1,
      500
    );
    camera.position.set(0, 5, 260);
    window.testscene3camera = camera;
    return camera;
  }



  initScenes(_webglContainer) {
    const sceneA = new THREE.Scene();//Bloom scene
    sceneA.background = new THREE.Color('#000000')
    sceneA.background.convertSRGBToLinear();
    const sceneB = new THREE.Scene();

    particles.init();
    sceneA.add(particles.container);

    background.init();
    sceneA.add(background.container);

    this.controls = new OrbitControls(
      this.camera,
      _webglContainer
    );
    return { sceneA, sceneB };
  }


  initPost(_appEngine, _webglContainer, _scenes, _camera, _datGUI) {
    const { sceneA, sceneB } = _scenes;

    const post = new Postprocess(
      _appEngine,
      _webglContainer,
      sceneA,
      sceneB,
      _camera,
      _datGUI
    );

    return post;
  }


  onResize(_webglContainerWidth, _webglContainerHeight) {
    this.camera.aspect = _webglContainerWidth / _webglContainerHeight;
    this.camera.updateProjectionMatrix();
    this.post.onResize(_webglContainerWidth, _webglContainerHeight);
    background.onResize(_webglContainerWidth, _webglContainerHeight);
  }


  animate(_dt) {
    particles.update(_dt);
    background.update(_dt, this.camera);
    this.post.render();
  }
}