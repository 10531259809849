import store from "@/store.js";
import * as THREE from "three";
import Postprocess from "./postprocess.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from 'dat.gui';
import planeVertexShader from "./shaders/plane.vert";
import planeFragmentShader from "./shaders/plane.frag";

export default class Scene2 {
  // eslint-disable-next-line no-unused-vars
  constructor(_appEngine, _webglContainer, _systemInfo) {
    this.isDebugActive = window.location.hash === "#debug" ? true : false;
    this.datGUI = this.isDebugActive ? new dat.GUI({ width: 300 }) : null;
    this.hasRendered = false;
    this.appEngine = _appEngine;
    this.assets = store.getters.assets();
    this.mesh = null;
    this.camera = this.initCamera(_webglContainer);
    this.scenes = this.initScenes(_webglContainer, _systemInfo);
    this.post = this.initPost(_appEngine, _webglContainer, this.scenes, this.camera, _systemInfo, this.datGUI);
    this.onResize(_webglContainer.clientWidth, _webglContainer.clientHeight, this.appEngine.camera);

    this.activeRatioSpeed = 1.0;
    this.activeRatio = 0;
    this.counter = 0;
    this.amountOfVersions = 11;
    this.isDown = false;
    //this.animate(1);
    return this;
  }



  initCamera(_webglContainer) {
    const camera = new THREE.PerspectiveCamera(
      70,
      _webglContainer.clientWidth / _webglContainer.clientHeight,
      0.1,
      50
    );
    camera.position.set(0, 1, 1);
    window.testscene3camera = camera;
    return camera;
  }



  // eslint-disable-next-line no-unused-vars
  initScenes(_webglContainer, _systemInfo) {
    const sceneA = new THREE.Scene();//Bloom scene
    const sceneB = new THREE.Scene();


    sceneA.background = new THREE.Color('#000000');

    const texture = this.assets.grid;
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    texture.minFilter = THREE.LinearFilter;

    const material = new THREE.ShaderMaterial({
      uniforms: {
        u_time: { value: 0.0 },
        u_speed: { value: 1.0 },
        u_tunnelSize: { value: 1.0 },
        u_wKeyRatio: { value: 1.0 },
        u_sKeyRatio: { value: 1.0 },
        u_aKeyRatio: { value: 1.0 },
        u_dKeyRatio: { value: 1.0 },
        u_activeRatio: { value: 0.0 },
        u_activeIndex: { value: 0.0 },
        u_colorInvertIndex: { value: 0.0 },
        u_texture: { value: texture },
        u_resolution: { value: new THREE.Vector4() },
      },
      vertexShader: planeVertexShader,
      fragmentShader: planeFragmentShader,
    });

    const geometry = new THREE.PlaneBufferGeometry(2, 2, 1, 1);
    this.mesh = new THREE.Mesh(geometry, material);

    window.xx = this.mesh;
    sceneA.add(this.mesh)


    //const folderPost = this.datGUI.addFolder("plane" + Math.random());

    //const blurFolder = folderPost.addFolder('blur')
    //blurFolder.add(this.horizontalBlurPass.material.uniforms.uStrength.value, 'x').step(0.001).min(0).max(10);
    // blurFolder.add(this.verticalBlurPass.material.uniforms.uStrength.value, 'y').step(0.001).min(0).max(10);

    // const planerFolder = folderPost.addFolder("planer");
    // planerFolder
    //   .add(this.mesh.material.uniforms.u_tunnelSize, "value")
    //   .step(0.001)
    //   .min(0)
    //   .max(300)
    //   .name("size");

    // planerFolder
    //   .add(this.mesh.material.uniforms.u_speed, "value")
    //   .step(0.001)
    //   .min(0)
    //   .max(3)
    //   .name("speed");

    // planerFolder
    //   .add(this.mesh.material.uniforms.u_distortionStrength, "value")
    //   .step(0.001)
    //   .min(0)
    //   .max(31)
    //   .name("dist");





    // planerFolder.open();


    this.controls = new OrbitControls(
      this.camera,
      _webglContainer
    );

    return { sceneA, sceneB };
  }


  initPost(_appEngine, _webglContainer, _scenes, _camera, _systemInfo, _datGUI) {
    const { sceneA, sceneB } = _scenes;
    const post = new Postprocess(
      _appEngine,
      _webglContainer,
      sceneA,
      sceneB,
      _camera,
      _systemInfo,
      _datGUI
    );


    return post;
  }


  onResize(_webglContainerWidth, _webglContainerHeight) {
    this.camera.aspect = _webglContainerWidth / _webglContainerHeight;
    this.camera.updateProjectionMatrix();
    this.post.onResize(_webglContainerWidth, _webglContainerHeight);

    //const imageAspect = _webglContainerHeight / _webglContainerWidth;
    const imageAspect = this.mesh.material.uniforms.u_texture.value.image.height / this.mesh.material.uniforms.u_texture.value.image.width;

    let a1;
    let a2;
    if (_webglContainerHeight / _webglContainerWidth > imageAspect) {
      a1 = (_webglContainerWidth / _webglContainerHeight) * imageAspect;
      a2 = 1;
    } else {
      a1 = 1;
      a2 = (_webglContainerHeight / _webglContainerWidth) / imageAspect;
    }

    this.mesh.material.uniforms.u_resolution.value.x = _webglContainerWidth;
    this.mesh.material.uniforms.u_resolution.value.y = _webglContainerHeight;
    this.mesh.material.uniforms.u_resolution.value.z = a1;
    this.mesh.material.uniforms.u_resolution.value.w = a2;
  }

  animate(_dt) {
    this.controls.update();

    this.mesh.material.uniforms.u_time.value += _dt;

    this.post.render();

    // this.keyWActiveRatio = Helpers.clamp(
    //   Helpers.saturate(this.keyWActiveRatio + (store.getters.keyWIsDown() ? _dt : -_dt) * this.activeRatioSpeed),
    //   0.0,
    //   1.0
    // );

    // this.keyAActiveRatio = Helpers.clamp(
    //   Helpers.saturate(this.keyAActiveRatio + (store.getters.keyAIsDown() ? _dt : -_dt) * this.activeRatioSpeed),
    //   0.0,
    //   1.0
    // );

    // this.keySActiveRatio = Helpers.clamp(
    //   Helpers.saturate(this.keySActiveRatio + (store.getters.keySIsDown() ? _dt : -_dt) * this.activeRatioSpeed),
    //   0.0,
    //   1.0
    // );

    // this.keyDActiveRatio = Helpers.clamp(
    //   Helpers.saturate(this.keyDActiveRatio + (store.getters.keyDIsDown() ? _dt : -_dt) * this.activeRatioSpeed),
    //   0.0,
    //   1.0
    // );


    // this.mesh.material.uniforms.u_wKeyRatio.value = this.keyWActiveRatio;
    // this.mesh.material.uniforms.u_aKeyRatio.value = this.keyAActiveRatio;
    // this.mesh.material.uniforms.u_sKeyRatio.value = this.keySActiveRatio;
    // this.mesh.material.uniforms.u_dKeyRatio.value = this.keyDActiveRatio;

    // this.mesh.material.uniforms.u_activeRatio.value = this.keyWActiveRatio;
    // this.mesh.material.uniforms.u_activeRatio.value = this.keyAActiveRatio;
    // this.mesh.material.uniforms.u_activeRatio.value = this.keySActiveRatio;
    // this.mesh.material.uniforms.u_activeRatio.value = this.keyDActiveRatio;



    if (store.getters.keyWIsDown() || store.getters.keyAIsDown() || store.getters.keySIsDown() || store.getters.keyDIsDown()
    ) {
      this.isDown = true;
    }

    if (this.isDown) {
      this.activeRatio += _dt * this.activeRatioSpeed;

      if (this.activeRatio > 1) {
        this.activeRatio = 0;
        this.isDown = false;
        this.counter++;
        if (this.counter === this.amountOfVersions) {
          this.counter = 0;
        }
        this.mesh.material.uniforms.u_activeIndex.value = this.counter;
      }

      this.mesh.material.uniforms.u_activeRatio.value = this.activeRatio;

    }

    this.mesh.material.uniforms.u_colorInvertIndex.value = store.getters.keyOkIsDown() ? 1.0 : 0.0;
  }
}